import { useFreewheelContext } from './FreewheelProvider';
import { useRef } from 'react';

const useFreewheel = () => {
  const { submitRequest } = useFreewheelContext();
  const sendRequest = (url) => submitRequest(url);
  const sendRequestRef = useRef(sendRequest);

  return sendRequestRef.current;
};

export default useFreewheel;
