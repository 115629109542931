import styled from 'styled-components';

export const Wrapper = styled.div`
  .nav {
    width: 100%;
    background: $white;
    color: $text;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    z-index: 5;
    padding: 14px 24px;

    .grand-prize-entries {
      font-weight: ${({ theme }) => theme.fonts.fontWeightBold};
      display: flex;
      align-items: center;

      .entries-total {
        border-radius: 50%;
        background-color: ${({ theme }) => theme.colors.cijCandy};
        color: ${({ theme }) => theme.colors.white};
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 10px;
      }
    }

    .logout {
      font-size: 13px;
      display: flex;
      align-items: baseline;

      @media screen and (max-width: 429.98px) {
        justify-content: center;
      }

      @media screen and (min-width: 430px) {
        justify-content: flex-end;
      }

      .btn-outline-light {
        font-size: 13px;
        margin: 0 0 1px 16px;
      }

      strong {
        text-transform: lowercase;
      }

      .logout-email {
        font-weight: ${({ theme }) => theme.fonts.fontWeightBold};
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-left: 2px;

        @media screen and (max-width: 429.98px) {
          max-width: calc(100vw - 170px);
        }

        @media screen and (min-width: 430px) and (max-width: 599.98px) {
          max-width: calc(50vw - 120px);
        }

        @media screen and (min-width: 600px) and (max-width: 959.98px) {
          max-width: calc(50vw - 170px);
        }

        @media screen and (min-width: 960px) {
          max-width: calc(50vw - 350px);
        }
      }
    }
  }

  .header-container {
    position: relative;

    &:before {
      content: '';
      background-position: 0 0;
      background-repeat: repeat-x;
      background-size: auto;
      position: absolute;
      left: 0;
      top: -20px;
      right: 0;
      bottom: 0;

      @media screen and (min-width: 768px) {
        top: 0;
      }
    }

    .header {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      padding: 15px 0;
      gap: 10px;
      position: relative;
      z-index: 100000;

      @media screen and (min-width: 767px) {
        padding: 20px;
      }

      @media screen and (min-width: 960px) {
        flex-direction: row;
      }

      &.logged-in {
        @media screen and (max-width: 991px) {
          padding: 20px 20px 30px;

          .sponsor-container {
            position: static;
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 8px;
            padding: 8px 16px;
            margin-top: 0;

            .sponsor-header {
              margin-bottom: 0;
              font-size: 8px;
            }

            .sponsor-image {
              width: 84px;
            }
          }

          .sponsor-container-col {
            flex-direction: column;

            .sponsor-header {
              font-size: 10px;
            }
          }
        }
      }

      .logo {
        max-width: 110%;
        position: relative;
        left: -18px;

        @media screen and (min-width: 576px) {
          max-width: 380px;
          max-width: 100%;
          position: static;
          left: 0;
        }

        @media screen and (min-width: 768px) {
          max-width: 380px;
        }
      }

      .logo-closed {
        @media screen and (min-width: ${({ theme }) => theme.breakpoints.xl}) {
          max-width: 400px;
        }
        
        @media screen and (min-width: ${({ theme }) => theme.breakpoints.xxl}) {
          max-width: 600px;
        }
      }
    }

    h5 {
      text-align: center;
    }

    .share-more-merry-mobile {
      @media screen and (max-width: 575.98px) {
        margin: 0 auto 10px auto;
        max-width: 250px;
        padding-top: 20px;
        display: block;
      }

      @media screen and (min-width: 576px) and (max-width: 959.98px) {
        display: block;
        padding-top: 28px;
        margin: 0 auto 10px auto;
        width: 250px;
      }

      @media screen and (min-width: 960px) and (max-width: 1279.98px) {
        display: none;
      }

      @media screen and (min-width: 1280px) and (max-width: 1797.98px) {
        margin: 0 auto 20px auto;
        width: 340px;
        height: 93.44px;
        padding-top: 32px;
        display: block;
      }

      @media screen and (min-width: 1798px) {
        display: none;
      }
    }

    .sponsor-container {
      background-color: ${({ theme }) => theme.colors.white};
      border-radius: 16px;
      text-align: center;
      justify-content: center;
      display: flex;
      top: calc(50% - 80px);
      margin-top: -20px;
      padding: 12px 20px;
      transition: 0.25s ease-in-out;
      flex-direction: column;

      @media screen and (max-width: 991.98px) {
        border-radius: 9px;
      }

      @media screen and (min-width: 767px) {
        left: calc(50% + 200px);
        position: absolute;
        padding: 24px 27px 13px;
        margin-top: 0;
      }

      @media screen and (min-width: 1440px) {
        left: calc(50% + 360px);
      }

      svg {
        height: 100%;
        width: 100%;
      }

      .sponsor-header {
        font-size: 8px;
        color: ${({ theme }) => theme.colors.black};
        margin: 0 0 6px;
        text-transform: uppercase;
        font-weight: 600;
        display: block;
        letter-spacing: 0.07em;

        img {
          margin: auto;
        }

        @media screen and (min-width: 576px) {
          font-size: 12px;
        }

        @media screen and (min-width: 960px) {
          font-size: 18px;
          margin-right: 0;
          margin-bottom: 1.35rem;
          line-height: 19px;
        }
      }

      .sponsor-image {
        max-width: 83px;
        margin: auto;

        @media screen and (min-width: 576px) {
          max-width: 150px;
        }

        @media screen and (min-width: 960px) {
          max-width: 154px;
        }
      }
    }

    .home-btn {
      position: absolute;
      left: 200px;
      top: calc(50% + 5px);
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      background: ${({ theme }) => theme.colors.white};
      border-radius: 25px;
      color: ${({ theme }) => theme.colors.underTheMistletoe};
      width: 162px;
      height: 36px;
      font-size: 20px;
      line-height: 27px;
      font-weight: ${({ theme }) => theme.fonts.fontWeightBold};
      text-transform: uppercase;

      @media screen and (max-width: 1535px) {
        left: 100px;
      }

      @media screen and (max-width: 991px) {
        height: 40px;
        width: 40px;
        border-radius: 40px;
        top: 20px;
        left: -22px;
      }

      @media screen and (max-width: 704px) {
        left: 0;
      }

      span {
        position: relative;
        top: 2px;

        @media screen and (max-width: 991px) {
          display: none;
        }
      }

      &__img-desktop {
        @media screen and (max-width: 991px) {
          display: none;
        }
      }

      &__img-mobile {
        display: none;
        @media screen and (max-width: 991px) {
          display: block;
        }
      }
    }

    .date-container {
      display: flex;
      align-items: flex-end;
      position: absolute;
      transition: 0.25s ease-in-out;

      @media screen and (max-width: 575.98px) {
        left: calc(50% + 75px);
        top: 12px;
      }

      @media screen and (min-width: 576px) and (max-width: 768px) {
        left: calc(50% + 140px);
        top: 5px;
      }

      @media screen and (min-width: 769px) and (max-width: 960px) {
        left: calc(50% + 170px);
        top: 0;
      }

      @media screen and (min-width: 960px) and (max-width: 1279.98px) {
        margin-top: -10px;
      }

      @media screen and (min-width: 1280px) {
        margin-top: -10px;
      }

      @media screen and (min-width: 961px) {
        left: calc(50% + 300px);
      }

      @media screen and (min-width: 1200px) {
        left: calc(50% + 300px);
      }

      .share-more-merry-desktop {
        @media screen and (max-width: 575.98px) {
          display: none;
        }

        @media screen and (min-width: 576px) and (max-width: 959.98px) {
          display: none;
        }

        @media screen and (min-width: 960px) and (max-width: 1279.98px) {
          display: block;
          width: 130px;
          height: 93.88px;
        }

        @media screen and (min-width: 1280px) and (max-width: 1797.98px) {
          display: none;
        }

        @media screen and (min-width: 1798px) {
          display: block;
          width: 180px;
          height: 150px;
          padding-bottom: 20px;
        }
      }

      .day-container {
        background-size: cover;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        @media screen and (max-width: 575.98px) {
          width: 103px;
          height: 88px;
        }

        @media screen and (min-width: 576px) and (max-width: 1279.98px) {
          width: 130px;
          height: 111px;
        }

        @media screen and (min-width: 1280px) {
          width: 188px;
          height: 161px;
        }

        p {
          color: $cij-candy;
          font-weight: ${({ theme }) => theme.fonts.fontWeightBold};
          text-align: center;
          margin-bottom: 0;
          text-transform: uppercase;

          @media screen and (max-width: 575.98px) {
            font-size: 15px;
            margin-top: -2px;
          }

          @media screen and (min-width: 576px) and (max-width: 1279.98px) {
            font-size: 20px;
            margin-top: -5px;
          }

          @media screen and (min-width: 1280px) {
            font-size: 31px;
            margin-top: -10px;
          }
        }
      }
    }
  }

  @media screen and (max-width: 429.98px) {
    .nav {
      justify-content: space-between;
      height: 60px !important;
      align-items: center;
      flex-wrap: nowrap;

      .grand-prize-entries {
        margin-top: 4px;
      }

      .logout {
        margin-top: 4px;
        line-height: 1 !important;
      }
    }
  }

  @media screen and (max-width: 599.98px) {
    .nav {
      justify-content: space-between;
      padding: 0 10px;

      .grand-prize-entries {
        font-size: 11px;

        .entries-total {
          height: 29px;
          width: 29px;
          margin-left: 6px;
        }
      }

      .logout {
        line-height: 42px;
      }
    }

    .header-container {
      h5 {
        margin-top: 20px;
      }
    }
  }

  @media screen and (min-width: 600px) and (max-width: 959.98px) {
    .nav {
      justify-content: space-between;
      padding: 0 10px;

      .grand-prize-entries {
        font-size: 12px;

        .entries-total {
          height: 29px;
          width: 29px;
          margin-left: 6px;
        }
      }

      .logout {
        line-height: 42px;
      }
    }

    .header-container {
      h5 {
        margin-top: 20px;
      }
    }
  }

  @media screen and (min-width: 960px) {
    .nav {
      .grand-prize-entries {
        font-size: 14px;

        .entries-total {
          height: 33px;
          width: 33px;
        }
      }

      .logout {
        line-height: 42px;
      }
    }

    .header-container {
      h5 {
        margin-top: 30px;
      }
    }
  }

  .sponsor-header {
    &.desktop {
      @media screen and (max-width: 960px) {
        display: none !important;
      }
    }

    &.mobile {
      align-self: center;
      margin-bottom: 0.5rem;
      margin-right: 0.5rem;
      font-size: 10px;
      display: none;

      @media screen and (min-width: 960px) {
        display: none !important;
      }
    }
  }

  .sponsor-divider {
    width: 100%;
    height: 0.2rem;
    display: block;
    background: linear-gradient(
      90deg,
      rgba(186, 0, 32, 0) 1%,
      rgba(186, 0, 32, 1) 26%,
      rgba(186, 0, 32, 1) 70%,
      rgba(186, 0, 32, 0) 99%
    );
    max-width: 400px;
    margin: 1rem auto;

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
      max-width: 600px;
    }
  }

  .header-prize-section {
    display: flex;
    justify-content: center;
    align-items: center;

    .prize-header-sponsor {
      max-width: 80px;
      height: auto;
      margin-left: 0.5rem;

      @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
        margin-left: 1rem;
        max-width: 138px;
      }
    }

    .prize-header-img {
      max-width: 220px;
      transition: all 0.2s linear;

      @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
        padding: 0;
        max-width: 100%;
      }
    }
  }

  .page-content.landing,
  .page-content.entry-form,
  .page-content.entry-success,
  .page-content.single-prize-page,
  .page-content.faqs,
  .page-content.all-prizes {
    .prize-copy-header {
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 19px;
      color: ${({ theme }) => theme.colors.text};
      font-size: 19px;
      transition: all 0.2s linear;
      text-align: center;
      padding: 0 15px;
      text-transform: uppercase;

      @media screen and (min-width: 1279px) {
        font-size: 32px;
        margin-bottom: 38px;
      }
    }
  }

  .page-content.single-prize-page {
    .prize-copy-header {
      margin-bottom: 0;

      @media screen and (min-width: 768px) {
        margin-bottom: 10px;
      }
    }
  }

  .prize-copy-header {
    max-width: 1100px;
    span {
      color: ${({ theme }) => theme.colors.candyCaneKisses};
    }
  }

  .prize-header-sponsor-sub {
    max-width: 80px;

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
      max-width: 140px;
      margin-right: -5.5rem;
    }
  }

  .prize-header-container {
    text-align: center;
  }

  .prize-header-sub-section {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 20px;
    margin-top: 1rem;
    padding: 16px 15px;
    text-align: center;
    transition: all 0.2s linear;
    color: $candy-cane-kisses;
    background-color: $white;
    border-radius: 24px;
    font-weight: $font-weight-semi;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.03em;
    gap: 15px;

    @media screen and (min-width: 1279px) {
      font-size: 24px;
      margin-bottom: 37px;
      flex-direction: row;
      gap: 25px;
    }

    span {
      color: $candy-cane-kisses;
    }

    .page-content.entry-form & {
      display: none;
    }

    .single-prize-page & {
      background-color: mix($white, transparent, 50%);
      padding: 12px 20px;
      display: inline-block;
      text-align: center;
    }
  }

  .daily-prize-header {
    max-width: 750px;

    .smaller {
      color: ${({ theme }) => theme.colors.green};
      font-size: 1rem;
      letter-spacing: 0;
      font-weight: ${({ theme }) => theme.fonts.fontWeightMedium};

      @media screen and (max-width: 350px) {
        font-size: 0.8rem;
      }

      @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
        font-size: 1.4rem;
      }
    }
  }

  .highlight {
    color: ${({ theme }) => theme.colors.cijCandy};
  }

  .bold {
    font-weight: ${({ theme }) => theme.fonts.Bold};
  }
`;
