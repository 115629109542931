import { PageData } from 'types/Tracking';
import * as Paths from './routes';

type PageDataMap = {
    [pageDataKey in
    | 'SignedOutLandingPage'
    | 'FormPage'
    | 'GamePage'
    | 'StatsPage']: PageData;
};

const pageTrackingData: PageDataMap = {
    SignedOutLandingPage: {
        title: 'Landing',
        path: Paths.SignedOutLandingRoute,
    },
    FormPage: {
        title: 'Form',
        path: Paths.SignUpFormRoute,
    },
    GamePage: {
        title: 'Game',
        path: Paths.GameRoute,
    },
    StatsPage: {
        title: 'Stats',
        path: '/stats',
    },
}

export const GetRouteNameFromPath = (path: string) =>
    Object.values(pageTrackingData).find((x) => x.path === path)?.title;

export default pageTrackingData;
