import { ShareSocialButtonWrapper } from 'components/stats/ShareResults.style';
import eventTrackingData from 'constants/eventData';
import { getTextToShare } from 'helpers/ShareHelper';
import copyIcon from 'images/icons/copy.svg';
import { trackEventData } from 'infrastructure/tracking/GoogleAnalytics';
import { ShareResultsClipboardProps } from 'types/Sharing';

const CopyButton: React.FC<ShareResultsClipboardProps> = ({
  solution,
  guesses,
  isGameLost,
  handleShareFailure,
  handleShareToClipboard,
}) => {
  const copyToClipboard = () => {
    trackEventData(eventTrackingData.CopyToClipboardClick);
    const textToShare = getTextToShare(isGameLost, guesses, solution, true);
    if (navigator.clipboard) {
      navigator.clipboard.writeText(textToShare).then(handleShareToClipboard).catch(handleShareFailure);
    } else {
      handleShareFailure();
    }
  };
  return (
    <ShareSocialButtonWrapper
      style={{
        backgroundColor: '#205B42',
      }}
      onClick={copyToClipboard}
    >
      <img src={copyIcon} alt={'Copy to clipboard'} />
    </ShareSocialButtonWrapper>
  );
};

export default CopyButton;
