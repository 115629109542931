import GetConfig, { Config } from 'helpers/GetConfig';
import { consoleLogEventData } from 'helpers/TrackConsoleLogs';
import { EventData } from 'types/Tracking';

interface EventProperties {
    event_category: string;
    send_to: string;
    event_label?: string;
}

let trackedInitialComScorePageView = false;

const sendToComScore: (config: Config) => void = ({ enableComScore }) => {
    if (enableComScore && window.COMSCORE) {
        window.COMSCORE.beacon({ c1: '2', c2: '8272010' });
        fetch('/pageview_candidate.txt');
    }
};

export const trackEventData: (trackingData: EventData) => void = ({ category, action, label }) =>
    trackEvent(category, action, label);

export const trackEvent: (category: string, action: string, label?: string) => void = (category, action, label) => {
    const { enableGoogleAnalytics, googleAnalyticsWebPropertyId } = GetConfig();
    consoleLogEventData(category, action, label);
    if (window.gtag && enableGoogleAnalytics) {
        const properties: EventProperties = {
            event_category: category,
            send_to: googleAnalyticsWebPropertyId,
        };
        if (label) {
            properties['event_label'] = label;
        }
        window.gtag('event', action, properties);
    }
};

export const trackPageView: (pageTitle: string, pagePath: string) => void = (pageTitle, pagePath) => {
    console.log('PAGE VIEW ', pageTitle, pagePath);
    const config = GetConfig();
    const gaEnabled = config.enableGoogleAnalytics;
    const fullPageTitle = `Hallmark Channel's Joy to the Word - ${pageTitle}`;

    if (trackedInitialComScorePageView) {
        sendToComScore(config);
    }
    trackedInitialComScorePageView = true;
    if (window.gtag && gaEnabled) {
        const properties = {
            page_title: fullPageTitle,
            page_location: `${config.appUrl.replace(/\/$/, '')}${pagePath}`,
            send_to: config.googleAnalyticsWebPropertyId
        };
        window.gtag('event', 'page_view', properties);
        if (config.crossDomainGoogleAnalyticsWebPropertyId) {
            const crossDomainProperties = {
                page_title: fullPageTitle,
                page_path: `/joy-to-the-word${pagePath}`,
            };
            window.gtag('config', config.crossDomainGoogleAnalyticsWebPropertyId, crossDomainProperties);
        }
    }
};
