import styled from 'styled-components';

export const Wrapper = styled.div`
  .game-page {
    max-width: 764px;
    margin: auto;
    margin-top: -20px;

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
      margin-bottom: 30px;
    }

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.xl}) {
      margin-top: -30px;
    }
  }

  .game-grid {
    background-color: ${({ theme }) => theme.colors.white};
    padding: 14px;
    margin: 0 22px;
    border-radius: 7px 7px 7px 7px;

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
      border-radius: 16px;
      margin: 0;
      padding: 29px 29px 14px;
    }

    .game-cell {
      max-width: 44px;
      max-height: 44px;
      border-color: #b7b6b6;

      .letter-container {
        @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
          position: relative;
          top: 2px;
        }
      }

      @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
        max-width: 70px;
        max-height: 70px;
      }

      &.absent {
        border: 0;
        background-color: ${({ theme }) => theme.colors.crimsonLove};
      }

      &.present {
        border: 0;
        background-color: ${({ theme }) => theme.colors.teal};
      }

      &.correct {
        border: 0;
        background-color: ${({ theme }) => theme.colors.green};
      }
    }

    .game-cell-10 {
      margin: 0 0.125rem;
      height: 64px;

      @media screen and (max-width: 470px) {
        height: 36px;
      }

      @media screen and (max-width: 412px) {
        height: 30px;
      }
    }
    .row-length-10 {
      margin-bottom: 0.25rem;
    }

    .game-cell-9 {
      margin: 0 0.25rem;
      height: 68px;

      @media screen and (max-width: 470px) {
        height: 36px;
      }

      @media screen and (max-width: 412px) {
        height: 30px;
      }
    }
    .row-length-9 {
      margin-bottom: 0.5rem;
    }

    .game-cell-8 {
      margin: 0 0.25rem;
      height: 71px;

      @media screen and (max-width: 470px) {
        height: 42px;
      }

      @media screen and (max-width: 412px) {
        height: 32px;
      }
    }

    .game-cell-7 {
      @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
        margin: 0 0.25rem;
      }
    }
    .row-length-7 {
      @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
        margin-bottom: 0.5rem;
      }
    }

    .row-length-10,
    .row-length-9,
    .row-length-8,
    .row-length-7,
    .row-length-6 {
      .game-cell:nth-child(1) {
        margin-left: 0;
      }
      .game-cell:last-child {
        margin-right: 0;
      }
    }

    .row-length-10:last-child,
    .row-length-9:last-child,
    .row-length-8:last-child,
    .row-length-7:last-child {
      @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
        margin-bottom: 1.125rem;
      }
    }
  }

  .home-btn {
    position: absolute;
  }

  .keyboard-pad {
    background-color: ${({ theme }) => theme.colors.white};
    padding: 16px;

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
      border-radius: 7px;
      padding: 24px 16px;
      box-shadow: 0px -20px 28px -22px rgba(0, 0, 0, 0.12);
    }

    .key {
      font-weight: ${({ theme }) => theme.fonts.fontWeightMedium};
      background-color: ${({ theme }) => theme.colors.lightGrey};
      max-height: 50px;

      &[aria-label='ENTER'] {
        color: ${({ theme }) => theme.colors.white};
        background-color: ${({ theme }) => theme.colors.green};
      }

      &:hover {
        opacity: 0.5;
      }

      &:active {
        opacity: 0.8;
      }

      &.absent {
        background-color: ${({ theme }) => theme.colors.crimsonLove};
      }

      &.present {
        background-color: ${({ theme }) => theme.colors.teal};
      }

      &.correct {
        background-color: ${({ theme }) => theme.colors.green};
      }
    }

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
      border-radius: 16px;
    }
  }

  .btn-share {
    background-color: ${({ theme }) => theme.colors.crimsonLove};
  }

  .view-stats {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 32px;
    margin-top: 20px;

    &.lg-word {
      margin-top: 1rem;
    }

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
      flex-direction: column;
      margin-bottom: 17px;
      gap: 17px;
    }

    h5 {
      font-family: ${({ theme }) => theme.fonts.albragrotesk};
      font-weight: ${({ theme }) => theme.fonts.fontWeightBold};
      font-size: 19px;
      line-height: 26px;
      text-transform: uppercase;
      color: ${({ theme }) => theme.colors.underTheMistletoe};
      max-width: 306px;

      @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
        font-size: 16px;
        line-height: 18px;
        text-align: center;
      }
    }

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      height: 59px;
      width: 329px;
      color: ${({ theme }) => theme.colors.white};
      border-radius: 40px;
      border: 2px solid ${({ theme }) => theme.colors.crimsonLove};
      background-color: ${({ theme }) => theme.colors.crimsonLove};
      filter: drop-shadow(0px 12px 12px rgba(211, 47, 60, 0.1));
      font-size: 25px;
      font-family: ${({ theme }) => theme.fonts.albragrotesk};
      font-weight: ${({ theme }) => theme.fonts.fontWeightBold};
      text-transform: uppercase;
      letter-spacing: 0.05em;
      transition: all 0.3s ease;

      &:hover {
        color: ${({ theme }) => theme.colors.crimsonLove};
        background-color: ${({ theme }) => theme.colors.white};
        border-color: ${({ theme }) => theme.colors.crimsonLove};

        #right-arrow-icon {
          transition: all 0.3s ease;
          stroke: ${({ theme }) => theme.colors.crimsonLove};
        }
      }

      @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
        height: 44.36px;
        width: 284px;
        border-radius: 29.8305px;
        font-size: 18.6441px;
      }

      @media screen and (max-width: 362px) {
        width: 100%;
      }

      svg {
        position: relative;
        top: -1px;

        @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
          height: 16.41px;
          width: 16.41px;
        }
      }
    }
  }

  .share-button-container {
    justify-content: center;
    width: 100%;

    button {
      @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
        width: 284px;
      }
    }
  }

  .closed-container {
    text-align: center;
    text-transform: uppercase;
    font-weight: ${({ theme }) => theme.fonts.fontWeightBold};
    max-width: 819px;

    .game-grid {
      padding: 40px 30px;
      align-items: center;

      @media screen and (max-width: ${({ theme }) => theme.breakpoints.lg}) {
        padding: 24px;
        margin: 0 22px;
      }
    }

    h1 {
      color: ${({ theme }) => theme.colors.crimsonLove};
      font-size: 32px;
      line-height: 44px;
      margin-bottom: 70px;

      @media screen and (max-width: ${({ theme }) => theme.breakpoints.lg}) {
        font-size: 19px;
        line-height: 26px;
        max-width: 240px;
        margin-bottom: 32px;
      }
    }

    h3 {
      font-size: 28px;
      line-height: 38px;
      margin-top: 70px;
      color: #2c2c2c;

      @media screen and (max-width: ${({ theme }) => theme.breakpoints.lg}) {
        font-size: 20px;
        line-height: 28px;
        margin-top: 32px;
      }
    }

    img {
      max-width: 250px;
      margin: 0 auto;

      @media screen and (max-width: ${({ theme }) => theme.breakpoints.lg}) {
        max-width: 226px;
      }

      @media screen and (max-width: 315px) {
        max-width: 100%;
      }
    }
  }

  .d-md {
    display: none;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
      display: block;
    }
  }

  .d-flex-md {
    display: none;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
      display: flex;
    }
  }
`;
