import { Wrapper } from "./GamePage.style";
import closedImage from "images/closed.svg"

const ClosedPage: React.FC = () => {
  return <Wrapper>
    <div className="game-page closed-container flex h-full flex-col">
      <div className="mx-auto flex w-full grow flex-col pt-2 pb-8 short:pb-2 short:pt-2">
        <div className="game-grid flex grow flex-col justify-center pb-6 short:pb-2">
          <h1>Thanks for playing Joy to the Word 2022.</h1>
          <img src={closedImage} alt="Closed" />
          <h3>The game has now finished.</h3>

        </div>
      </div>
    </div>
  </Wrapper>
};

export default ClosedPage;
