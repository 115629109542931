import { trackEventData } from '../../infrastructure/tracking/GoogleAnalytics';
import { MovieTitle, OpenModalButton, Wrapper } from './HintBox.style';
import HintModal from './HintModal';
import eventTrackingData from 'constants/eventData';
import eyeIcon from 'images/icons/eye.svg';
import { useState } from 'react';

interface Props {
  movieTitle: string;
  movieUrl: string;
  imageUrl: string;
  videoUrl: string;
  description: string | null;
}

const HintBox = ({ movieTitle, movieUrl, imageUrl, videoUrl, description }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const target = '_blank';

  const handleOnModalCloseClick = () => {
    setIsOpen(false);
  };

  const handleOnOpenModalButtonClick = () => {
    trackEventData(eventTrackingData.HintClick);
    setIsOpen(true);
  };

  const renderTitle = () => {
    if (!description) {
      return (
        <>
          <span>See a hint from the movie: </span>
          <MovieTitle className="font-bold ml-1">
            <a href={movieUrl} target={target} onClick={() => trackEventData(eventTrackingData.HintMovieClick)}>
              {movieTitle}
            </a>
          </MovieTitle>
        </>
      );
    } else {
      return <span>{description} </span>;
    }
  };

  return (
    <>
      <Wrapper className="flex flex-col	md:flex-row items-center md:py-4 md:px-6 rounded-xl mb-4 md:mb-6">
        {renderTitle()}
        <OpenModalButton
          className="flex items-center gap-2.5 mt-2.5 md:mt-0 md:ml-auto text-white pt-2 pb-1.5 px-12 md:pt-2.5 md:pb-2 md:px-14"
          onClick={handleOnOpenModalButtonClick}
        >
          <img className="mb-0.5" src={eyeIcon} alt="Eye icon" />
          <span>HINT</span>
        </OpenModalButton>
      </Wrapper>
      <HintModal
        isOpen={isOpen}
        handleOnCloseClick={handleOnModalCloseClick}
        imageUrl={imageUrl}
        videoUrl={videoUrl}
        description={description}
      />
    </>
  );
};

export default HintBox;
