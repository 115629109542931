import App from './App';
import { AlertProvider } from './context/AlertContext';
import './index.css';
import { FreewheelProvider } from 'infrastructure/freewheel/FreewheelProvider';
import { store } from 'infrastructure/redux/store';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

const root = createRoot(document.getElementById('root')!);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <FreewheelProvider>
        <AlertProvider>
          <App />
        </AlertProvider>
      </FreewheelProvider>
    </Provider>
  </React.StrictMode>
);
