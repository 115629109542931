import { StyledRightArrow } from './Keys.style';

const RightArrow = () => {
  return (
    <StyledRightArrow>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1}
        stroke="currentColor"
        className="arrow-right h-6"
      >
        <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3" />
      </svg>
    </StyledRightArrow>
  );
};

export default RightArrow;
