import styled from 'styled-components';

export const Wrapper = styled.div`
  .alert {
    top: 80px;
    background-color: ${({ theme }) => theme.colors.crimsonLove};
    z-index: 999999;

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
      top: 200px;
    }

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
      top: 230px;
    }

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.xxl}) {
      top: 350px;
    }
  }
`;
