import { BEST_STREAK_TEXT, CURRENT_STREAK_TEXT, SUCCESS_RATE_TEXT, TOTAL_TRIES_TEXT } from '../../constants/strings';
import { useState, useRef, useLayoutEffect } from 'react';

type Props = {
  gamesPlayed: number;
  winPercentage: number;
  currentStreak: number;
  maxStreak: number;
};

const StatItem = ({ label, value, className }: { label: string; value: number; className?: string }) => {
  const valueRef = useRef<HTMLElement>(null);
  const [valueLength, setValueLength] = useState(0);

  function valueFormatter(value: number) {
    if (value > 999 && value < 1000000) {
      return (value / 1000).toFixed(1) + 'K';
    } else if (value > 1000000) {
      return (value / 1000000).toFixed(1) + 'M';
    } else if (value < 900) {
      return value;
    }
  }

  useLayoutEffect(() => {
    if (valueRef.current !== null) {
      setValueLength(valueRef!.current!.textContent!.length);
    }
  }, []);

  return (
    <div className={`items-center justify-center dark:text-white stat-item ${className}`}>
      <b ref={valueRef} className={valueLength === 1 || valueLength === 2 ? 'stat-item--lg' : 'stat-item--sm'}>
        {valueFormatter(value)}
      </b>
      <span>{label}</span>
    </div>
  );
};

export const StatBar = ({ gamesPlayed, winPercentage, currentStreak, maxStreak }: Props) => {
  return (
    <div className="statbar flex justify-center">
      <StatItem label={TOTAL_TRIES_TEXT} value={gamesPlayed} />
      <StatItem label={SUCCESS_RATE_TEXT} value={winPercentage} />
      <StatItem label={CURRENT_STREAK_TEXT} value={currentStreak} />
      <StatItem label={BEST_STREAK_TEXT} value={maxStreak} />
    </div>
  );
};
