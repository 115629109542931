import GamePage from 'components/pages/GamePage';
import SignedOutLandingPage from 'components/pages/SignedOutLandingPage';
import * as Paths from 'constants/routes';
import { Route, Routes, useLocation } from 'react-router-dom';
import { Navigate } from 'react-router-dom';

const AppRoutes = () => {
  const location = useLocation();

  return (
    <Routes location={location}>
      {/* Navigation Routes */}
      <Route path={Paths.SignedOutLandingRoute} element={<SignedOutLandingPage />} key="landing" />
      <Route path={Paths.GameRoute} element={<GamePage />} key="signinform" />

      {/* <Route path="/closewindow" element={<CloseWindow />} key="closewindow" /> */}

      {/* Error Routes */}
      <Route path="*" element={<Navigate to={Paths.SignedOutLandingRoute} replace />} key="notfound" />
    </Routes>
  );
};

export default AppRoutes;
