import styled from 'styled-components';

export const Wrapper = styled.div`
  .modal__title {
    h3 {
      text-align: center;
      font-size: 30px;
    }
  }

  .absent {
    border-color: ${({ theme }) => theme.colors.crimsonLove};
    background-color: ${({ theme }) => theme.colors.crimsonLove};
  }

  .present {
    border-color: ${({ theme }) => theme.colors.teal};
    background-color: ${({ theme }) => theme.colors.teal};
  }

  .correct {
    border-color: ${({ theme }) => theme.colors.green};
    background-color: ${({ theme }) => theme.colors.green};
  }
`;
