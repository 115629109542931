import iconChecked from '../../images/icons/checked.svg';
import iconChevron from '../../images/icons/chevron.svg';
import styled from 'styled-components';

export const Wrapper = styled.div`
  .sign-out-landing-page {
    background-color: ${({ theme }) => theme.colors.white};
    max-width: 764px;
    margin: auto;
    border-radius: 16px;
    padding: 16px;
    margin-bottom: 30px;
    position: relative;
    top: -35px;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.lg}) {
      top: -10px;
    }

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
      padding: 40px;
      margin-bottom: 100px;
    }

    h2 {
      font-size: 23px;
      text-align: center;
      color: #a80808;
      font-weight: ${({ theme }) => theme.fonts.fontWeightBold};
      text-transform: uppercase;
      margin-bottom: 20px;
      line-height: 1.2;

      @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
        font-size: 30px;
        margin-bottom: 24px;
      }

      @media screen and (min-width: ${({ theme }) => theme.breakpoints.lg}) {
        font-size: 32px;
      }
    }

    ul {
      li {
        background-color: #fdf4f4;
        margin-bottom: 20px;
        border-radius: 52px 16px 16px 52px;
        padding: 8px 12px;
        display: flex;
        align-items: center;
        font-weight: ${({ theme }) => theme.fonts.fontWeightRegular};
        line-height: 1.2;

        @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
          font-size: 20px;
          font-weight: ${({ theme }) => theme.fonts.fontWeightMedium};
          padding: 12px 16px;
          line-height: 1.3;
        }

        &:before {
          content: '';
          display: inline-block;
          background-image: url(${iconChecked});
          background-size: 34px;
          min-width: 34px;
          width: 34px;
          height: 34px;

          @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
            background-size: 43px;
            min-width: 43px;
            width: 43px;
            height: 43px;
          }
        }

        span {
          margin-left: 12px;

          @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
            margin-left: 20px;
          }
        }
      }
    }

    .btn {
      font-size: 18px;
      font-weight: ${({ theme }) => theme.fonts.fontWeightBold};
      padding: 10px 30px;
      border-radius: 30px;
      text-transform: uppercase;
      letter-spacing: 0.05em;
      width calc(100% - 40px);

      @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
        font-size: 24px;
        padding: 12px 46px;
        width: auto;
      }

      &.btn-primary {
        color: ${({ theme }) => theme.colors.white};
        background-color: #a80808;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        transition: filter 0.5s ease;

        &:after {
            display: inline-block;
            content: '';
            background-image: url(${iconChevron});
            background-repeat: no-repeat;
            background-size: 12px;
            width: 12px;
            height: 18px;
            margin-left 12px;

            @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
                background-size: 15px;
                width: 15px;
                height: 21px;
                margin-left 15px;
            }
        }

        &:hover,
        &:active,
        &:focus {
            filter: brightness(90%);
        }
      }
    }

    .btn-wrapper {
      text-align: center;
    }
  }
`;
