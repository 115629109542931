import GetConfig from 'helpers/GetConfig';

const { consoleLogEvents } = GetConfig();

export const consoleLogEventData = (category: string, action: string, label?: string) => {
    if (consoleLogEvents) {
        // filter with /Category*|Action*/
        console.group('Event Tracking');
        console.log(`Category: ${category}`);
        console.log(`Action: ${action}`);
        if (label) {
            console.log(`Label: ${label}`);
        }
        console.groupEnd();
    }
};
