import { GUESS_DISTRIBUTION_TEXT, NEW_WORD_TEXT, STATISTICS_TITLE } from '../../constants/strings';
import { useAppSelector } from '../../hooks/useReduxHelper';
import { Histogram } from '../stats/Histogram';
import { StatBar } from '../stats/StatBar';
import { CountdownWrapper } from './Countdown.style';
import { Wrapper } from './StatsModal.style';
import ShareResults, { ShareButton } from 'components/stats/ShareResults';
import { GetTimeToNextWordUrl } from 'constants/apiUrls';
import callApi from 'infrastructure/api/CallApi';
import { useState, useEffect } from 'react';
import Countdown from 'react-countdown';

type Props = {
  solution: string;
  guesses: string[];
  isGameLost: boolean;
  isGameWon: boolean;
  handleShareToClipboard: () => void;
  handleShareFailure: () => void;
  numberOfGuessesMade: number;
};

export const StatsModal = ({
  solution,
  guesses,
  isGameLost,
  isGameWon,
  handleShareToClipboard,
  handleShareFailure,
  numberOfGuessesMade,
}: Props) => {
  const { gamesPlayed, winPercentage, currentStreak, maxStreak, guessDistribution } = useAppSelector(
    (state) => state.user
  );

  const [tomorrow, setTomorrow] = useState<number>(0);

  useEffect(() => {
    callApi<number>(GetTimeToNextWordUrl).then(({ data }) => setTomorrow(Date.now() + data * 1000));
  }, []);
  const countdownRenderer = ({ hours, minutes, seconds }: { hours: number; minutes: number; seconds: number }) => {
    // Render a countdown
    return (
      <>
        <div className="countdown__column">
          <span className="countdown__value">{hours}</span>
          <h6 className="countdown__value-label">HOURS</h6>
        </div>
        <div className="countdown__colon">
          <span></span>
          <span></span>
        </div>
        <div className="countdown__column">
          <span className="countdown__value">{minutes}</span>
          <h6 className="countdown__value-label">MINUTES</h6>
        </div>
        <div className="countdown__colon">
          <span></span>
          <span></span>
        </div>
        <div className="countdown__column">
          <span className="countdown__value">{seconds}</span>
          <h6 className="countdown__value-label">SECONDS</h6>
        </div>
      </>
    );
  };

  if (gamesPlayed <= 0) {
    return (
      <Wrapper className="stats-modal__container modal__container">
        <h3 className="stats__title" style={{ color: '#fff' }}>
          {STATISTICS_TITLE}
        </h3>
        <StatBar
          winPercentage={winPercentage}
          currentStreak={currentStreak}
          maxStreak={maxStreak}
          gamesPlayed={gamesPlayed}
        />
      </Wrapper>
    );
  }
  return (
    <Wrapper>
      <div className="stats__container">
        <div className="stats__content">
          <h3 className="stats__title">{STATISTICS_TITLE}</h3>
          <StatBar
            winPercentage={winPercentage}
            currentStreak={currentStreak}
            maxStreak={maxStreak}
            gamesPlayed={gamesPlayed}
          />
          <h4>{GUESS_DISTRIBUTION_TEXT}</h4>
          <Histogram
            isGameWon={isGameWon}
            numberOfGuessesMade={numberOfGuessesMade}
            guessDistribution={guessDistribution}
          />
          {(isGameLost || isGameWon) && (
            <div className="mt-5 items-center items-stretch justify-center flex-col text-center dark:text-white sm:mt-6 countdown">
              {tomorrow > 0 && (
                <>
                  <h5>{NEW_WORD_TEXT}</h5>
                  <CountdownWrapper>
                    <Countdown
                      className="text-lg font-medium text-gray-900 dark:text-gray-100"
                      date={tomorrow}
                      daysInHours={true}
                      renderer={countdownRenderer}
                    />
                  </CountdownWrapper>
                </>
              )}

              <div className="share-button-container d-flex-md">
                <ShareButton
                  solution={solution}
                  guesses={guesses}
                  isGameLost={isGameLost}
                  handleShareToClipboard={handleShareToClipboard}
                  handleShareFailure={handleShareFailure}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <ShareResults
        solution={solution}
        guesses={guesses}
        isGameLost={isGameLost}
        handleShareFailure={handleShareFailure}
        handleShareToClipboard={handleShareToClipboard}
      />
    </Wrapper>
  );
};
