// TODOs:
// 1. refactor and tidy up media queries across the site
// 2. ditch unused variables
// 3. create short references for variables

const colors = {
  bgGreyDark: '#383e40',
  background: '#F2F0F1',
  text: '#333',
  darkRed: '#A91D37',
  white: '#fff',
  red: '#DB1E36',
  crimsonLove: '#A80808',
  darkGreen: '#328140',
  oceanGreen: '#3FA077',
  green: '#205B42',
  teal: '#80D6DB',
  ctcBlue: '#347AA7',
  cijCandy: '#DF3237',
  ctcDarkred: '#ba0020',
  candyCaneKisses: '#D33B3B',
  darkGray: '#b8b8b8',
  softMist: '#F6F6F6',
  underTheMistletoe: '#205B42',
  inputOutline: 'dark-gray',
  captionText: '#686868',
  placeholder: '#2B2B2B',
  borderColor: '#CECECE',
  grey: '#575757',
  lightGrey: '#DBDBDB',
  twitterBlue: '#1b95e0',
  pinterestRed: '#bd081c',
  darkRedHover: 'darken(dark-red, 10%)',
  greenHover: 'darken(green, 10%)',
  cijCandyHover: 'darken(cij-candy, 10%)',
  darkGreenHover: 'darken(dark-green, 10%)',
  ctcBlueHover: 'darken(ctc-blue, 10%)',
  twitterBlueHover: 'darken(twitter-blue, 10%)',
  pinterestRedHover: 'darken(pinterest-red, 10%)',
  whiteHover: 'darken(white, 10%)',
};

const fonts = {
  albragrotesk: "'Albra Grotesk', Tahoma, Helvetica, sans-serif",
  fontWeightLight: '300',
  fontWeightBook: '400',
  fontWeightMedium: '500',
  fontWeightSemi: '600',
  fontWeightBold: '700',
};

export const THEME = {
  colors: {
    ...colors,
  },
  fonts: {
    ...fonts,
  },
  breakpoints: {
    xs: '320px',
    sm: '576px',
    md: '768px',
    lg: '992px',
    xl: '1200px',
    xxl: '1444px',
  },
  media: {
    tablet: '(max-width: 768px)',
  },
};
