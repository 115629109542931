import { EventData } from "types/Tracking";

type EventTrackingMap = {
    [eventDataKey in
    | 'PlayNowClick'
    | 'SponsorClick'
    | 'FormPrivacyClick'
    | 'FormTermsClick'
    | 'FormSponsorClick'
    | 'FormSponsorPrivacyClick'
    | 'SignUpFormSuccess'
    | 'SignUpFormError'
    | 'HintClick'
    | 'HintMovieClick'
    | 'HintVideoStart'
    | 'HintVideoEnd'
    | 'HomeClick'
    | 'InvalidWord'
    | 'IncorrectGuess'
    | 'CorrectGuess'
    | 'StatsClick'
    | 'StatsHeaderClick'
    | 'RecipeClick'
    | 'MobileShareClick'
    | 'TwitterShareClick'
    | 'PinterestShareClick'
    | 'CopyToClipboardClick'
    | 'LogoutClick']: EventData;
};

const global = 'Global';
const landingPageName = 'Landing Page';
const formPageName = 'Form Page';
const gamePageName = 'Game Page';

const eventTrackingData: EventTrackingMap = {
    PlayNowClick: {
        action: 'Play Now Button Click',
        category: landingPageName,
        label: '',
    },
    SponsorClick: {
        action: 'Sponsor Logo Click',
        category: global,
        label: '',
    },
    FormPrivacyClick: {
        action: 'Form Privacy Link Click',
        category: formPageName,
        label: '',
    },
    FormTermsClick: {
        action: 'Form Terms Link Click',
        category: formPageName,
        label: '',
    },
    FormSponsorClick: {
        action: 'Form Sponsor Link Click',
        category: formPageName,
        label: '',
    },
    FormSponsorPrivacyClick: {
        action: 'Form Sponsor Privacy Link Click',
        category: formPageName,
        label: '',
    },
    SignUpFormSuccess: {
        action: 'Sign Up Form Success',
        category: formPageName,
        label: '',
    },
    SignUpFormError: {
        action: 'Sign Up Form Error',
        category: formPageName,
        label: '',
    },
    HintClick: {
        action: 'Hint Click',
        category: gamePageName,
        label: '',
    },
    HintMovieClick: {
        action: 'Hint Movie Link Click',
        category: gamePageName,
        label: '',
    },
    HintVideoStart: {
        action: 'Hint Video Play',
        category: gamePageName,
        label: '',
    },
    HintVideoEnd: {
        action: 'Hint Video End',
        category: gamePageName,
        label: '',
    },
    HomeClick: {
        action: 'Home Click',
        category: global,
        label: '',
    },
    InvalidWord: {
        action: 'Invalid Word',
        category: gamePageName,
        label: '',
    },
    IncorrectGuess: {
        action: 'Incorrect Guess',
        category: gamePageName,
        label: '',
    },
    CorrectGuess: {
        action: 'Correct Guess',
        category: gamePageName,
        label: '',
    },
    StatsClick: {
        action: 'Stats Click',
        category: gamePageName,
        label: '',
    },
    StatsHeaderClick: {
        action: 'Stats Header Click',
        category: gamePageName,
        label: '',
    },
    RecipeClick: {
        action: 'Recipe Click',
        category: gamePageName,
        label: '',
    },
    MobileShareClick: {
        action: 'Mobile Share Click',
        category: global,
        label: '',
    },
    TwitterShareClick: {
        action: 'Twitter Share Click',
        category: global,
        label: '',
    },
    PinterestShareClick: {
        action: 'Pinterest Share Click',
        category: global,
        label: '',
    },
    CopyToClipboardClick: {
        action: 'Copy to Clipboard Click',
        category: global,
        label: '',
    },
    LogoutClick: {
        action: 'Logout Click',
        category: global,
        label: '',
    },
};

export default eventTrackingData;