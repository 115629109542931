import eventTrackingData from '../../constants/eventData';
import { GameRoute, SignUpFormRoute } from '../../constants/routes';
import usePageView from '../../hooks/usePageView';
import { trackEventData } from '../../infrastructure/tracking/GoogleAnalytics';
import { selectIsSignedIn } from '../../slices/User/UserSlice';
import { Wrapper } from './SignOutLandingPage.style';
import pageTrackingData from 'constants/pageData';
import { useAppSelector } from 'hooks/useReduxHelper';
import { Link } from 'react-router-dom';

const SignedOutLandingPage: React.FC = () => {
  const isSignedIn = useAppSelector(selectIsSignedIn);

  usePageView(pageTrackingData.SignedOutLandingPage);
  return (
    <Wrapper>
      <div className="container">
        <div className="sign-out-landing-page">
          <h2>Visit every day to guess a new word!</h2>
          <ul>
            <li>
              <span>Guess the Holiday-themed word in 6 tries or less!</span>
            </li>
            <li>
              <span>
                A correct letter turns dark green, a correct letter in the wrong place turns teal, and an incorrect
                letter turns red.
              </span>
            </li>
            <li>
              <span>Letters can be used more than once.</span>
            </li>
            <li>
              <span>
                At any point, click the 'Hint' button for a helpful picture or video clip from one of our Holiday movies
                that includes the word!
              </span>
            </li>
          </ul>
          <div className="btn-wrapper">
            <Link
              to={isSignedIn ? GameRoute : SignUpFormRoute}
              onClick={() => trackEventData(eventTrackingData.PlayNowClick)}
            >
              <button className="btn btn-primary btn-center">Play Now</button>
            </Link>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default SignedOutLandingPage;
