function RightArrowIcon() {
  return (
    <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.73828 20.0676L16.9506 11.2838L5.73828 2.5"
        stroke="white"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        id="right-arrow-icon"
      />
    </svg>
  );
}

export default RightArrowIcon;
