// import { selectIsWebView } from 'slices/WebView/WebViewSlice';
import { getTextToShare } from '../../helpers/ShareHelper';
import { ShareSocialButtonWrapper } from 'components/stats/ShareResults.style';
import eventTrackingData from 'constants/eventData';
import pinterestIcon from 'images/icons/pinterest.svg';
import { trackEventData } from 'infrastructure/tracking/GoogleAnalytics';
import React from 'react';
import { ShareResultsProps } from 'types/Sharing';

const PinterestButton: React.FC<ShareResultsProps> = ({ solution, guesses, isGameLost }) => {
  //  const isWebView = useAppSelector(selectIsWebView);
  const isWebView = false;

  function shareOnPinterest() {
    trackEventData(eventTrackingData.PinterestShareClick);
    const textToShare = getTextToShare(isGameLost, guesses, solution, true, '➡️');

    const imageUrl = 'https://1240369105.rsc.cdn77.org/joytotheword/images/static/joytotheword-og.jpg';
    const pinterestUrl = `http://www.pinterest.com/pin/create/button?media=${imageUrl}&description=${encodeURIComponent(
      textToShare
    )}`;
    if (isWebView) {
      window.location.href = pinterestUrl;
    } else {
      window.open(pinterestUrl, 'PinIt', 'location=0,status=0,width=800,height=650');
    }
  }

  return (
    <ShareSocialButtonWrapper
      style={{
        backgroundColor: '#D7143A',
      }}
      onClick={shareOnPinterest}
    >
      <img src={pinterestIcon} alt={'Pinterest'} />
    </ShareSocialButtonWrapper>
  );
};

export default PinterestButton;
