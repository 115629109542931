import { Wrapper } from './BaseModal.style';
import { Dialog, Transition } from '@headlessui/react';
import classNames from 'classnames';
import closeIcon from 'images/icons/close.svg';
import { Fragment } from 'react';
import React from 'react';

type Props = {
  title?: string;
  modalName: string;
  children: React.ReactNode;
  isOpen: boolean;
  handleClose: () => void;
  shouldHaveMinimalPadding?: boolean;
};

export const BaseModal = ({
  title,
  modalName,
  children,
  isOpen,
  handleClose,
  shouldHaveMinimalPadding = false,
}: Props) => {
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 z-100000 overflow-y-auto" onClose={handleClose}>
        <div className="flex min-h-full items-center justify-center py-10 px-4 text-center sm:p-0">
          <Wrapper>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 min-h-screen bg-black bg-opacity-70 transition-opacity dialog-overlay" />
            </Transition.Child>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div
                className={classNames(
                  `inline-block transform rounded-lg bg-white  text-left align-bottom shadow-xl transition-all dark:bg-gray-800 sm:my-8 sm:w-full sm:max-w-sm sm:align-middle ${modalName}-modal__container modal__container`,
                  shouldHaveMinimalPadding ? 'p-4' : 'pt-4 px-2.5 pb-6 md:px-10 md:py-16'
                )}
              >
                <button
                  onClick={handleClose}
                  tabIndex={0}
                  aria-pressed="false"
                  className="absolute right-0 top-0 close"
                >
                  <img src={closeIcon} alt="Close" className="cursor-pointer" />
                </button>

                <div className="modal__content">
                  {title ? (
                    <h3 className="modal__title">
                      {title.split('\\n').map((line, index) => (
                        <React.Fragment key={index}>
                          {line}
                          <br />
                        </React.Fragment>
                      ))}
                    </h3>
                  ) : null}
                  {children}
                </div>
              </div>
            </Transition.Child>
          </Wrapper>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
