import { unicodeSplit } from 'lib/words'
import { getGuessStatuses } from 'lib/statuses';

export const getTextToShare = (lost: boolean, guesses: string[], solution: string, includeUrl: boolean = false, joinElement?: string) =>
    `Hallmark Channel's Joy to the Word ${lost ? 'X' : guesses.length
    }/6\n\n` +
    generateEmojiGrid(
        solution,
        guesses,
        getEmojiTiles(),
        includeUrl,
        joinElement
    )

export const generateEmojiGrid = (
    solution: string,
    guesses: string[],
    tiles: string[],
    includeUrl: boolean,
    joinElement?: string,
) => {
    return guesses
        .map((guess) => {
            const status = getGuessStatuses(solution, guess)
            const splitGuess = unicodeSplit(guess)

            return splitGuess
                .map((_, i) => {
                    switch (status[i]) {
                        case 'correct':
                            return tiles[0]
                        case 'present':
                            return tiles[1]
                        default:
                            return tiles[2]
                    }
                })
                .join('')
        })
        .join(joinElement ?? '\n') + (includeUrl ? '\n Play Now at https://joytotheword.hallmarkchannel.com' : '');
}

const getEmojiTiles = () => {
    let tiles: string[] = []
    tiles.push('🟩')
    tiles.push('🟦')
    tiles.push('⬜')
    return tiles
}