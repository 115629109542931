import styled from 'styled-components';

export const Wrapper = styled.div`
  .loading-container {
    padding: 10vh 0;

    svg {
      margin: auto;
      max-width: 80px;
    }
  }
`;
