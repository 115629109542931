import { UserDto } from '../../types/Shared';

export const stateKey = 'HallmarkJoyToTheWordUserData';

interface LocalStorageState {
  user: UserDto;
}

export const loadState = () => {
  try {
    const serializedState = localStorage.getItem(stateKey);
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState) as LocalStorageState;
  } catch (err) {
    return undefined;
  }
};

export const saveState = (state: any) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem(stateKey, serializedState);
  } catch {
    console.log('Could not save state to local storage');
  }
};
