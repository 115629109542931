import { RootState } from '../../infrastructure/redux/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DailyWordDto } from 'types';

const initialState: DailyWordDto = {
  id: '',
  word: '',
  movieTitle: '',
  movieUrl: '',
  hintImageUrl: '',
  hintVideoUrl: '',
  hintDescriptionOverride: null,
  recipeImageUrl: '',
  recipeUrl: '',
};

export const dailyWordSlice = createSlice({
  name: 'dailyWord',
  initialState,
  reducers: {
    setDailyWord: (_, action: PayloadAction<DailyWordDto>) => {
      return action.payload;
    },
  },
});

export const { setDailyWord } = dailyWordSlice.actions;

export const selectDailyWord = (state: RootState) => state.dailyWord;

export default dailyWordSlice.reducer;
