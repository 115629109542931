import { Progress } from './Progress';

type Props = {
  isGameWon: boolean;
  numberOfGuessesMade: number;
  guessDistribution: number[];
};

const isCurrentDayStatRow = (isGameWon: boolean, numberOfGuessesMade: number, i: number) => {
  return isGameWon && numberOfGuessesMade === i + 1;
};

export const Histogram = ({ isGameWon, numberOfGuessesMade, guessDistribution }: Props) => {
  const maxValue = Math.max(...guessDistribution, 1);
  return (
    <div className="justify-left m-2 columns-1 text-sm dark:text-white stats__histogram">
      {guessDistribution.map((value, i) => (
        <Progress
          key={i}
          index={i}
          isCurrentDayStatRow={isCurrentDayStatRow(isGameWon, numberOfGuessesMade, i)}
          size={90 * (value / maxValue)}
          label={String(value)}
        />
      ))}
    </div>
  );
};
