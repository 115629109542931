import eventTrackingData from '../../../constants/eventData';
import HomeIconDesktop from '../../../images/icons/home-desktop.svg';
import HomeIconMobile from '../../../images/icons/home-mobile.svg';
import JTTWLogo from '../../../images/jttw-logo.svg';
import SponsorLogo from '../../../images/sponsor-logo.svg';
import { trackEventData } from '../../../infrastructure/tracking/GoogleAnalytics';
import { Wrapper } from './Header.style';
import classNames from 'classnames';
import { GameRoute, SignedOutLandingRoute } from 'constants/routes';
import GetConfig from 'helpers/GetConfig';
import { Link, useLocation } from 'react-router-dom';

interface Props {
  isLoggedIn: boolean;
}

const { isClosed } = GetConfig();
const Header = ({ isLoggedIn }: Props) => {
  const { pathname } = useLocation();

  return (
    <Wrapper>
      <div className="header-container">
        <div className="container">
          <div className={classNames('header', isLoggedIn && 'logged-in')}>
            {pathname === GameRoute && !isClosed && (
              <Link
                to={SignedOutLandingRoute}
                className="home-btn"
                onClick={() => trackEventData(eventTrackingData.HomeClick)}
              >
                <img className="home-btn__img-mobile" src={HomeIconMobile} alt="" />
                <img className="home-btn__img-desktop" src={HomeIconDesktop} alt="" />
                <span>Home</span>
              </Link>
            )}
            <div>
              <Link to={SignedOutLandingRoute}>
                <img
                  className={classNames(
                    'logo',
                    pathname === GameRoute && !isClosed && 'hidden lg:block',
                    isClosed && 'logo-closed'
                  )}
                  src={JTTWLogo}
                  alt="Joy To The World Logo"
                />
              </Link>
            </div>
            <div
              className={classNames(
                'sponsor-container',
                (pathname !== GameRoute || isClosed) && 'sponsor-container-col'
              )}
            >
              <span className="sponsor-header"> Presented by</span>
              <a
                href="https://www.campbells.com/?utm_source=HallmarkChannel&utm_medium=Game&utm_campaign=JoyToTheWord"
                rel="noreferrer"
                target="_blank"
                onClick={() => trackEventData(eventTrackingData.SponsorClick)}
              >
                <img src={SponsorLogo} alt="Campbell's" className="img-fluid sponsor-image" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};
export default Header;
