import styled from 'styled-components';

export const StyledRightArrow = styled.div`
  svg {
    width: 17px;
  }
`;

export const StyledBackspace = styled.div`
  svg {
    width: 21.44px;
  }
`;
