import { GameRoute } from '../../constants/routes';
import { trackEventData } from '../../infrastructure/tracking/GoogleAnalytics';
import { removeGameStateFromLocalStorage } from '../../lib/localStorage';
import { NavigateStatsState } from '../../types/Shared';
import { LogoutButton, Navbar, StatsButton } from './NavBar.style';
import eventTrackingData from 'constants/eventData';
import { SignedOutLandingRoute, SignUpFormRoute } from 'constants/routes';
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHelper';
import logoutIcon from 'images/icons/logout.svg';
import statsIcon from 'images/icons/stats.svg';
import logoMobile from 'images/logo-mobile.png';
import { useLocation, useNavigate } from 'react-router-dom';
import { logout, selectUserName } from 'slices/User/UserSlice';

const locationState: NavigateStatsState = { showStats: true };

// @TODO: Should be hidden when there is no user
const NavBar = () => {
  const userName = useAppSelector(selectUserName);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { pathname } = useLocation();

  const onLogoutClick = () => {
    trackEventData(eventTrackingData.LogoutClick);
    dispatch(logout());
    removeGameStateFromLocalStorage();
  };

  if (pathname === SignedOutLandingRoute || pathname === SignUpFormRoute) {
    return null;
  }

  return (
    <Navbar className="navbar relative z-10000 p-1 lg:px-4 lg:py-2 bg-white flex justify-between items-center">
      <StatsButton
        className="flex gap-3 items-center p-2 lg:pt-3 lg:pr-4 lg:pb-2 lg:pl-3 font-bold uppercase rounded"
        onClick={() => {
          trackEventData(eventTrackingData.StatsHeaderClick);
          navigate(GameRoute, { state: locationState });
        }}
      >
        <img className="lg:mb-0.5" src={statsIcon} alt="Chart icon" />
        <span className="hidden lg:block text-lg">Your stats</span>
      </StatsButton>

      <img className="lg:hidden h-fit" src={logoMobile} alt="Joy to the word" width="235" height="37" />
      <div className="flex gap-4 items-center text-lg uppercase font-medium">
        <div className="hidden lg:block">
          <span>Welcome, </span>
          <span className="font-bold">{userName}</span>
        </div>
        <LogoutButton className="uppercase" onClick={onLogoutClick}>
          <span className="not-you-btn hidden lg:block">Not you?</span>
          <img className="lg:hidden" src={logoutIcon} alt="Logout" />
        </LogoutButton>
      </div>
    </Navbar>
  );
};

export default NavBar;
