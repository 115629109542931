import { trackEventData } from '../../infrastructure/tracking/GoogleAnalytics';
import { ShareResultsClipboardProps } from '../../types/Sharing';
import { ShareButtonWrapper, Wrapper } from './ShareResults.style';
import { ShareIcon } from '@heroicons/react/outline';
import CopyButton from 'components/sharing/CopyButton';
import PinterestButton from 'components/sharing/PinterestButton';
import TwitterButton from 'components/sharing/TwitterButton';
import eventTrackingData from 'constants/eventData';
import { SHARE_TEXT, STATISTICS_SHARE_TITLE } from 'constants/strings';
import { shareStatus } from 'lib/share';

type ShareButtonProps = {
  solution: string;
  guesses: string[];
  isGameLost: boolean;
  handleShareToClipboard: () => void;
  handleShareFailure: () => void;
};

export const ShareButton = ({
  solution,
  guesses,
  isGameLost,
  handleShareToClipboard,
  handleShareFailure,
}: ShareButtonProps) => {
  return (
    <ShareButtonWrapper
      type="button"
      className="inline-flex w-full items-center justify-center px-4 py-2 text-center focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
      onClick={() => {
        trackEventData(eventTrackingData.MobileShareClick);
        shareStatus(solution, guesses, isGameLost, handleShareToClipboard, handleShareFailure);
      }}
    >
      {SHARE_TEXT}
      <ShareIcon className="mr-2 h-6 w-6 cursor-pointer dark:stroke-white" />
    </ShareButtonWrapper>
  );
};

const ShareResults: React.FC<ShareResultsClipboardProps> = ({
  solution,
  guesses,
  isGameLost,
  handleShareFailure,
  handleShareToClipboard,
}) => {
  return (
    <Wrapper>
      <h6>{STATISTICS_SHARE_TITLE}</h6>
      <TwitterButton solution={solution} guesses={guesses} isGameLost={isGameLost} />
      <PinterestButton solution={solution} guesses={guesses} isGameLost={isGameLost} />
      <CopyButton
        solution={solution}
        guesses={guesses}
        isGameLost={isGameLost}
        handleShareFailure={handleShareFailure}
        handleShareToClipboard={handleShareToClipboard}
      />
    </Wrapper>
  );
};

export default ShareResults;
