import iconChecked from '../../images/icons/checked.svg';
import recipeImage from '../../images/recipe.png';
import styled from 'styled-components';

export const Wrapper = styled.div`
  .dialog-overlay {
    cursor: pointer;
  }

  .close {
    transform: translate(30%, -30%);
  }

  .hint-modal__content {
    img {
      border-radius: 16px;
    }
  }

  .modal__content {
    h3 {
      text-align: center;
      font-size: 32px;
      font-weight: ${({ theme }) => theme.fonts.fontWeightBold};
      font-family: ${({ theme }) => theme.fonts.albragrotesk};
      line-height: 44px;
      text-transform: uppercase;
      color: ${({ theme }) => theme.colors.crimsonLove};
      margin-bottom: 24px;

      @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
        font-size: 23px;
        line-height: 27.6px;
        margin-left: 7px;
        margin-right: 7px;
      }
    }

    video {
      border-radius: 16px;
    }

    .play-video-button {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
      cursor: pointer;
      background-color: transparent;
      border: none;
      outline: none;

      img {
        width: 72px;
        height: 72px;
        opacity: 0.7;
        transition: opacity 0.3s ease-in-out;

        &:hover {
          opacity: 1;
        }

        @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
          width: 151px;
          height: 151px;
        }
      }
    }
  }

  .modal__container {
    max-width: 764px;
    border-radius: 16px;
    box-shadow: 0px 28px 44px rgba(0, 0, 0, 0.05);
  }

  .stats-modal__container {
    h5 {
      color: ${({ theme }) => theme.colors.underTheMistletoe};
      font-weight: ${({ theme }) => theme.fonts.fontWeightBold};
      font-size: 29px;
      line-height: 29px;
      text-transform: uppercase;
      margin-bottom: 16px;

      @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
        font-size: 16px;
        letter-spacing: 0.05em;
        line-height: 16px;
        margin-bottom: 17px;
      }
    }

    .modal__title {
      font-size: 29px;
      line-height: 29px;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      margin-bottom: 27px;
      color: ${({ theme }) => theme.colors.crimsonLove};

      @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
        font-size: 16px;
        line-height: 16px;
        margin-bottom: 16px;
        color: ${({ theme }) => theme.colors.underTheMistletoe};
      }
    }

    .stat-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      gap: 8px;
      padding: 32px 5px;
      height: 169px;
      width: 134px;
      color: ${({ theme }) => theme.colors.white};
      background-color: ${({ theme }) => theme.colors.crimsonLove};
      box-shadow: 0px 12px 18px -8px rgba(211, 47, 60, 0.3);
      border-radius: 12px;

      @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
        gap: 4px;
        width: 66px;
        height: 84px;
        padding: 12px 5px;
        border-radius: 12px;
      }

      b {
        line-height: 67px;
        font-size: 67px;

        @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
          font-size: 33px;
          line-height: 33px;
        }
      }

      .stat-item--lg {
        font-size: 67px;
        line-height: 100%;

        @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
          font-size: 33px;
          line-height: 100%;
        }
      }

      .stat-item--sm {
        font-size: 55px;
        line-height: 100%;
        margin-bottom: 10px;

        @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
          font-size: 28px;
          line-height: 100%;
          margin-bottom: 4.75px;
        }
      }

      span {
        font-size: 21px;
        line-height: 21px;
        letter-spacing: 0.06em;
        text-align: center;
        max-width: 78px;

        @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
          font-size: 11px;
          line-height: 11px;
        }
      }
    }

    .statbar {
      gap: 43px;
      margin-bottom: 32px;

      @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
        gap: 12px;
      }
    }

    h4 {
      font-size: 29px;
      line-height: 100%;
      letter-spacing: 0.05em;
      text-align: center;
      text-transform: uppercase;
      color: ${({ theme }) => theme.colors.underTheMistletoe};
      font-weight: ${({ theme }) => theme.fonts.fontWeightBold};

      @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
        font-size: 16px;
      }
    }

    .histogram-row__index {
      font-size: 21px;
      line-height: 29px;
      font-weight: ${({ theme }) => theme.fonts.fontWeightSemi};

      @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
        font-size: 19.0569px;
        line-height: 26px;
      }
    }

    .stats__histogram-row {
      margin: 7px 0;
    }

    .histogram-row__line {
      height: 41px;
      font-weight: ${({ theme }) => theme.fonts.fontWeightSemi};
      margin-left: 11px;

      @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
        height: 27px;
      }

      div {
        height: 100%;
        border-radius: 8px;
        font-size: 21px;
        line-height: 100%;
        padding-right: 20px;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
          font-size: 19px;
          padding-right: 15px;
        }
      }
    }

    .progress--bold {
      background-color: ${({ theme }) => theme.colors.underTheMistletoe};
      box-shadow: 0px 12px 18px -8px rgba(0, 98, 76, 0.12);
      color: ${({ theme }) => theme.colors.white};
    }

    .progress--light {
      background-color: rgba(64, 160, 120, 0.2);
      color: ${({ theme }) => theme.colors.underTheMistletoe};
    }

    .share-button {
      height: 50px;
      width: 264px;
      border: 2px solid ${({ theme }) => theme.colors.underTheMistletoe};
      border-radius: 32px;
      color: ${({ theme }) => theme.colors.underTheMistletoe};
      gap: 7.5px;
      letter-spacing: 0.04em;
      font-size: 16px;
      font-weight: ${({ theme }) => theme.fonts.fontWeightBold};
      line-height: 100%;
      text-transform: uppercase;
    }

    .d-md {
      display: none;

      @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
        display: block;
      }
    }
  }

  dialog::backdrop {
    background: rgba(0, 0, 0, 0.5);
  }

  ul {
    li {
      background-color: #fdf4f4;
      margin-bottom: 20px;
      border-radius: 52px 16px 16px 52px;
      padding: 8px 12px;
      display: flex;
      align-items: center;
      font-weight: ${({ theme }) => theme.fonts.fontWeightRegular};
      line-height: 1.2;

      @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
        font-size: 20px;
        font-weight: ${({ theme }) => theme.fonts.fontWeightMedium};
        padding: 12px 16px;
        line-height: 1.3;
        margin-bottom: 40px;
      }

      @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
        margin-left: 7px;
        margin-right: 7px;
        border-radius: 41.3589px 9.54435px 9.54435px 41.3589px;
      }

      &:before {
        content: '';
        display: inline-block;
        background-image: url(${iconChecked});
        background-size: 34px;
        min-width: 34px;
        width: 34px;
        height: 34px;

        @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
          background-size: 43px;
          min-width: 43px;
          width: 43px;
          height: 43px;
        }
      }

      span {
        margin-left: 17.5px;

        @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
          margin-left: 22px;
        }
      }
    }
  }

  .game-cell {
    height: 69.63px;
    width: 69.63px;
    font-size: 44.99px;
    border-radius: 6.18903px;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
      height: 30.76px;
      width: 30.76px;
      font-size: 19.8796px;
      border-radius: 2.73447px;
      border: 0.683619px solid ${({ theme }) => theme.colors.black};
    }

    .letter-container {
      margin-top: 4px !important;

      @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
        margin-top: 1px !important;
      }
    }
  }

  .modal__row-description {
    text-align: center;
    font-size: 18px;
    color: ${({ theme }) => theme.colors.black};
    line-height: 25px;
    margin: 12px 0 24px;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
      font-size: 14px;
      line-height: 19px;
    }
  }

  .recipe-modal__container {
    padding: 0;

    .modal__content {
      h3 {
        font-size: 30px;
        padding: 32px 0 0;
        margin: 0 auto 23px;
        max-width: 601px;
        line-height: 41px;

        @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
          font-size: 16px;
          line-height: 22px;
          padding: 18px 15px 0;
          margin-bottom: 19px;
        }
      }
    }
  }

  .recipe-modal__background {
    position: relative;
    width: 764px;
    max-width: 764px;
    height: 429px;
    /* background-image: url(${recipeImage}); */
    background-size: cover;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
      width: unset;
      height: 230px;
    }

    .campbells-logo {
      position: absolute;
      bottom: 20px;
      right: 17px;

      @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
        width: 127.24px;
        height: 34.76px;
        right: 17px;
        bottom: 20px;
      }
    }

    .recipe-name {
      position: absolute;
      top: 121px;

      @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
        width: 192.29px;
        height: 67.67px;
        top: 56px;
      }
    }
  }

  .get-recipe-btn {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    bottom: 35px;
    left: 36px;
    border-radius: 40px;
    background-color: ${({ theme }) => theme.colors.white};
    width: 245px;
    height: 59px;
    cursor: pointer;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
      width: 159px;
      height: 38px;
      gap: 6px;
      left: 16px;
      bottom: 18px;
    }

    span {
      letter-spacing: 0.05em;
      text-transform: uppercase;
      font-size: 25px;
      line-height: 25px;
      color: ${({ theme }) => theme.colors.crimsonLove};
      font-weight: ${({ theme }) => theme.fonts.fontWeightBold};

      @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
        font-size: 16px;
        line-height: 16px;
      }
    }

    svg {
      position: relative;
      top: -1px;
      @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
        width: 14px;
        height: 14px;
        top: unset;
      }
    }

    #right-arrow-icon {
      stroke: ${({ theme }) => theme.colors.crimsonLove};
    }
  }

  .mb-0 {
    margin-bottom: 0 !important;
  }
`;

export const RecipeModalBackground = styled.div<{
  recipeDesktop: string;
  recipeMobile: string;
}>`
  position: relative;
  width: 764px;
  max-width: 764px;
  height: 429px;
  background-image: ${(props) => `url(${props.recipeDesktop})`};
  background-size: cover;
  border-radius: 0px 0px 16px 16px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
    width: unset;
    height: 230px;
    background-image: ${(props) => `url(${props.recipeMobile})`};
  }
`;
