import classNames from 'classnames';

type Props = {
  index: number;
  size: number;
  label: string;
  isCurrentDayStatRow: boolean;
};

export const Progress = ({ index, size, label, isCurrentDayStatRow }: Props) => {
  const currentRowClass = classNames('text-xs font-medium text-blue-100 text-center p-0.5', {
    'progress--bold': isCurrentDayStatRow,
    'progress--light': !isCurrentDayStatRow,
  });
  return (
    <div className="justify-left items-center flex stats__histogram-row">
      <div className="w-2 items-center justify-center histogram-row__index">{index + 1}</div>
      <div className="w-full histogram-row__line">
        <div style={{ width: `${8 + size}%` }} className={currentRowClass}>
          {label}
        </div>
      </div>
    </div>
  );
};
