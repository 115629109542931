import { SignedOutLandingRoute } from '../../constants/routes';
import getDictionary from '../../helpers/GetDictionary';
import { isInDictionary } from '../../helpers/GetDictionary';
import { useAppDispatch, useAppSelector } from '../../hooks/useReduxHelper';
import callApi from '../../infrastructure/api/CallApi';
import { trackPageView, trackEventData } from '../../infrastructure/tracking/GoogleAnalytics';
import { login as setUser, selectUserId, selectIsNew } from '../../slices/User/UserSlice';
import { NavigateStatsState, UserDto } from '../../types/Shared';
import { Wrapper } from './GamePage.style';
import { AlertContainer } from 'components/alerts/AlertContainer';
import { Grid } from 'components/grid/Grid';
import HintBox from 'components/hint/HintBox';
import { Keyboard } from 'components/keyboard/Keyboard';
import Loader from 'components/loader/Loader';
import { InfoModal } from 'components/modals/InfoModal';
import { RecipeModal } from 'components/modals/RecipeModal';
import { StatsModal } from 'components/modals/StatsModal';
import ShareResults, { ShareButton } from 'components/stats/ShareResults';
import { PostResultUrl } from 'constants/apiUrls';
import eventTrackingData from 'constants/eventData';
import pageTrackingData from 'constants/pageData';
import {
  LONG_ALERT_TIME_MS,
  MAX_CHALLENGES,
  REVEAL_TIME_MS,
  WELCOME_INFO_MODAL_MS, // WELCOME_INFO_MODAL_MS,
} from 'constants/settings';
import {
  CORRECT_WORD_MESSAGE,
  GAME_COPIED_MESSAGE,
  NOT_ENOUGH_LETTERS_MESSAGE,
  SHARE_FAILURE_TEXT,
  VIEW_STATS_TEXT,
  WIN_MESSAGES,
  WORD_NOT_FOUND_MESSAGE, // WORD_NOT_FOUND_MESSAGE,
} from 'constants/strings';
import { useAlert } from 'context/AlertContext';
import { default as GraphemeSplitter } from 'grapheme-splitter';
import RightArrowIcon from 'images/icons/RightArrowIcon';
import { loadGameStateFromLocalStorage, saveGameStateToLocalStorage } from 'lib/localStorage';
import { unicodeLength } from 'lib/words';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { selectDailyWord } from 'slices/DailyWord/DailyWordSlice';

const GamePage = () => {
  const dispatch = useAppDispatch();

  function showWinningMessage() {
    const winMessage = WIN_MESSAGES[Math.floor(Math.random() * WIN_MESSAGES.length)];
    const delayMs = REVEAL_TIME_MS * selectedDailyWord.word.length;
    showSuccessAlert(winMessage, {
      delayMs,
      onClose: () => setIsRecipeModalOpen(selectedDailyWord?.recipeImageUrl ? true : false),
    });
  }

  const selectedDailyWord = useAppSelector(selectDailyWord);
  useEffect(() => {
    if (selectedDailyWord?.word) {
      getDictionary(selectedDailyWord.word.length).then((dictionary) => setDictionary(dictionary));
    }
  }, [selectedDailyWord]);

  const navigate = useNavigate();
  const location = useLocation();
  const initialShowStats = (location.state as NavigateStatsState)?.showStats ?? false;

  const { showError: showErrorAlert, showSuccess: showSuccessAlert } = useAlert();

  const [currentGuess, setCurrentGuess] = useState('');
  const [isGameWon, setIsGameWon] = useState(false);
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);
  const [isStatsModalOpen, setIsStatsModalOpen] = useState(initialShowStats);
  const [isGameEnded, setIsGameEnded] = useState(false);
  const [isRecipeModalOpen, setIsRecipeModalOpen] = useState(false);
  const [currentRowClass, setCurrentRowClass] = useState('');
  const [isGameLost, setIsGameLost] = useState(false);
  const [isRevealing, setIsRevealing] = useState(false);
  const userId = useAppSelector(selectUserId);
  const isNew = useAppSelector(selectIsNew);
  const [guesses, setGuesses] = useState<string[]>([]);
  const [dictionary, setDictionary] = useState<string[]>([]);

  useEffect(() => {
    const page = isStatsModalOpen ? pageTrackingData.StatsPage : pageTrackingData.GamePage;
    trackPageView(page.title, page.path);
  }, [isStatsModalOpen]);

  useEffect(() => {
    setIsStatsModalOpen(initialShowStats);
  }, [initialShowStats]);

  useEffect(() => {
    const loaded = loadGameStateFromLocalStorage();

    if (selectedDailyWord.word && loaded?.solution === selectedDailyWord.word) {
      const gameWasWon = loaded.guesses.includes(selectedDailyWord.word);
      if (gameWasWon) {
        setIsGameWon(true);
        if (selectedDailyWord?.recipeImageUrl) {
          setIsRecipeModalOpen(true);
        }
      }
      if (loaded.guesses.length === MAX_CHALLENGES && !gameWasWon) {
        setIsGameLost(true);
        showErrorAlert(CORRECT_WORD_MESSAGE(selectedDailyWord.word), {
          onClose: () => {
            if (selectedDailyWord?.recipeImageUrl) {
              setIsRecipeModalOpen(true);
            }
          },
        });
      }
      setGuesses(loaded.guesses);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDailyWord.word]);

  useEffect(() => {
    if (selectedDailyWord.word) {
      saveGameStateToLocalStorage({ guesses, solution: selectedDailyWord.word });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [guesses]);

  useEffect(() => {
    if (isNew) {
      setTimeout(() => {
        setIsInfoModalOpen(true);
      }, WELCOME_INFO_MODAL_MS);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!userId) {
      navigate(SignedOutLandingRoute);
    }
  }, [navigate, userId]);

  const clearCurrentRowClass = () => {
    setCurrentRowClass('');
  };

  const onChar = (value: string) => {
    if (
      unicodeLength(`${currentGuess}${value}`) <= selectedDailyWord.word.length &&
      guesses.length < MAX_CHALLENGES &&
      !isGameWon
    ) {
      setCurrentGuess(`${currentGuess}${value}`);
    }
  };

  const onGameEnded = (hasWon: boolean) => {
    callApi<UserDto>(PostResultUrl, 'POST', {
      userId,
      dailyWordId: selectedDailyWord?.id,
      isSuccess: hasWon,
      guessNumber: guesses.length + 1,
    }).then(({ data }) => {
      dispatch(setUser(data));
    });
  };

  const onDelete = () => {
    setCurrentGuess(new GraphemeSplitter().splitGraphemes(currentGuess).slice(0, -1).join(''));
  };

  const onEnter = () => {
    if (isGameWon || isGameLost) {
      return;
    }

    if (!(unicodeLength(currentGuess) === selectedDailyWord.word.length)) {
      setCurrentRowClass('jiggle');
      return showErrorAlert(NOT_ENOUGH_LETTERS_MESSAGE, {
        onClose: clearCurrentRowClass,
      });
    }

    if (dictionary.length && !isInDictionary(currentGuess, dictionary, selectedDailyWord.word)) {
      setCurrentRowClass('jiggle');
      trackEventData(eventTrackingData.InvalidWord);
      return showErrorAlert(WORD_NOT_FOUND_MESSAGE, {
        onClose: clearCurrentRowClass,
      });
    }

    setIsRevealing(true);
    // turn this back off after all
    // chars have been revealed
    setTimeout(() => {
      setIsRevealing(false);
    }, REVEAL_TIME_MS * selectedDailyWord.word.length);
    const winningWord = currentGuess === selectedDailyWord.word;

    if (
      unicodeLength(currentGuess) === selectedDailyWord.word.length &&
      guesses.length < MAX_CHALLENGES &&
      !isGameWon
    ) {
      setGuesses([...guesses, currentGuess]);
      setCurrentGuess('');

      if (winningWord) {
        onGameEnded(true);
        setIsGameWon(true);
        showWinningMessage();
        trackEventData(eventTrackingData.CorrectGuess);
        return;
      }

      trackEventData(eventTrackingData.IncorrectGuess);

      if (guesses.length === MAX_CHALLENGES - 1) {
        onGameEnded(false);
        setIsGameLost(true);
        showErrorAlert(CORRECT_WORD_MESSAGE(selectedDailyWord.word), {
          delayMs: REVEAL_TIME_MS * selectedDailyWord.word.length + 1,
          onClose: () => {
            if (selectedDailyWord?.recipeImageUrl) {
              setIsRecipeModalOpen(true);
            }
          },
        });
      }
    }
  };

  useEffect(() => {
    if (isGameWon) {
      setIsGameEnded(true);
    }

    if (isGameLost) {
      setTimeout(() => {
        setIsGameEnded(true);
      }, (selectedDailyWord.word.length + 1) * REVEAL_TIME_MS);
    }
  }, [isGameWon, isGameLost, showSuccessAlert, selectedDailyWord.word]);

  const viewStats = () => {
    trackEventData(eventTrackingData.StatsClick);
    setIsStatsModalOpen(true);
  };

  if (!selectedDailyWord.word || !selectedDailyWord) {
    return <Loader />;
  }

  return (
    <Wrapper>
      <div className="game-page flex h-full flex-col">
        <div className="mx-auto flex w-full grow flex-col pt-2 pb-8 short:pb-2 short:pt-2">
          {isStatsModalOpen ? (
            <StatsModal
              solution={selectedDailyWord.word}
              guesses={guesses}
              isGameLost={isGameLost}
              isGameWon={isGameWon}
              handleShareToClipboard={() => showSuccessAlert(GAME_COPIED_MESSAGE)}
              handleShareFailure={() =>
                showErrorAlert(SHARE_FAILURE_TEXT, {
                  durationMs: LONG_ALERT_TIME_MS,
                })
              }
              numberOfGuessesMade={guesses.length}
            />
          ) : (
            <>
              <div className="game-grid flex grow flex-col justify-center pb-6 short:pb-2">
                <HintBox
                  description={selectedDailyWord.hintDescriptionOverride}
                  imageUrl={selectedDailyWord.hintImageUrl}
                  movieTitle={selectedDailyWord.movieTitle}
                  movieUrl={selectedDailyWord.movieUrl}
                  videoUrl={selectedDailyWord.hintVideoUrl}
                />
                <Grid
                  dailyWord={selectedDailyWord.word}
                  guesses={guesses}
                  currentGuess={currentGuess}
                  isRevealing={isRevealing}
                  currentRowClassName={currentRowClass}
                />
                {isGameEnded ? (
                  <>
                    <div className="view-stats">
                      <h5>{VIEW_STATS_TEXT}</h5>
                      <button
                        onClick={viewStats}
                        className={`view-stats__button ${selectedDailyWord.word.length > 6 && 'lg-word'}`}
                      >
                        VIEW STATS
                        <RightArrowIcon />
                      </button>
                    </div>
                    <div className="share-button-container d-flex-md">
                      <ShareButton
                        solution={selectedDailyWord.word}
                        guesses={guesses}
                        isGameLost={isGameLost}
                        handleShareToClipboard={() => showSuccessAlert(GAME_COPIED_MESSAGE)}
                        handleShareFailure={() =>
                          showErrorAlert(SHARE_FAILURE_TEXT, {
                            durationMs: LONG_ALERT_TIME_MS,
                          })
                        }
                      />
                    </div>
                  </>
                ) : null}
              </div>
              {isGameEnded ? (
                <ShareResults
                  solution={selectedDailyWord.word}
                  guesses={guesses}
                  isGameLost={isGameLost}
                  handleShareToClipboard={() => showSuccessAlert(GAME_COPIED_MESSAGE)}
                  handleShareFailure={() =>
                    showErrorAlert(SHARE_FAILURE_TEXT, {
                      durationMs: LONG_ALERT_TIME_MS,
                    })
                  }
                />
              ) : (
                <div className="keyboard-pad">
                  <Keyboard
                    onChar={onChar}
                    onDelete={onDelete}
                    onEnter={onEnter}
                    solution={selectedDailyWord.word}
                    guesses={guesses}
                    isRevealing={isRevealing}
                  />
                </div>
              )}
              <InfoModal isOpen={isInfoModalOpen} handleClose={() => setIsInfoModalOpen(false)} />
              <RecipeModal
                isOpen={isRecipeModalOpen}
                handleClose={() => setIsRecipeModalOpen(false)}
                recipeImageUrl={selectedDailyWord.recipeImageUrl}
                recipeUrl={selectedDailyWord.recipeUrl}
              />
              <AlertContainer />
            </>
          )}
        </div>
      </div>
    </Wrapper>
  );
};

export default GamePage;
