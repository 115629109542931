import { REVEAL_TIME_MS } from '../../constants/settings';
import { CharStatus } from '../../lib/statuses';
import classnames from 'classnames';

type Props = {
  value?: string;
  status?: CharStatus;
  isRevealing?: boolean;
  isCompleted?: boolean;
  wordLength?: number;
  position?: number;
};

export const Cell = ({ value, status, isRevealing, isCompleted, wordLength, position = 0 }: Props) => {
  const isFilled = value && !isCompleted;
  const shouldReveal = isRevealing && isCompleted;
  const animationDelay = `${position * REVEAL_TIME_MS}ms`;

  const classes = classnames(
    'game-cell xxshort:w-11 xxshort:h-11 short:text-2xl short:w-12 short:h-12 w-20 h-20 border-solid border-2 flex items-center justify-center mx-1 text-xl sm:text-4xl font-bold rounded dark:text-white',
    {
      'bg-white': !status,
      'border-black ': value && !status,
      'absent text-white ': status === 'absent',
      'correct text-white': status === 'correct',
      'present text-white': status === 'present',

      'cell-fill-animation': isFilled,
      'cell-reveal': shouldReveal,
    }
  );

  return (
    <div className={`${classes} game-cell-${wordLength}`} style={{ animationDelay }}>
      <div className="letter-container" style={{ animationDelay }}>
        {value}
      </div>
    </div>
  );
};
