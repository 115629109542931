import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserDto } from 'types';
import { RootState } from '../../infrastructure/redux/store';

const initialState: UserDto = {
  id: '',
  name: '',
  gamesPlayed: 0,
  winPercentage: 0,
  currentStreak: 0,
  maxStreak: 0,
  guessDistribution: [0, 0, 0, 0, 0, 0],
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    login: (_, action: PayloadAction<UserDto>) => {
      return action.payload;
    },
    logout: () => initialState,
  },
});

export const { login, logout } =
  userSlice.actions;

export const selectIsSignedIn = (state: RootState) => Boolean(state.user.id);
export const selectUserName = (state: RootState) => state.user.name;
export const selectUserId = (state: RootState) => state.user.id;
export const selectIsNew = (state: RootState) => Boolean(state.user.isNew);

export default userSlice.reducer;
