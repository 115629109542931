import './App.css';
import GlobalFonts from './fonts/fonts';
import AdStyles from './styles/adStyles';
import GlobalStyles from './styles/globalStyles';
import { defaultTheme } from './theme';
import AnimatedBanner from 'components/ads/AnimatedBanner';
import FixedAd from 'components/ads/FixedAd';
import HallmarkFooter from 'components/hallmark/HallmarkFooter';
import HallmarkHeader from 'components/hallmark/HallmarkHeader';
import Header from 'components/hallmark/Header/Header';
import NavBar from 'components/navigation/NavBar';
import ClosedPage from 'components/pages/ClosedPage';
import { FetchDailyWordUrl } from 'constants/apiUrls';
import GetConfig from 'helpers/GetConfig';
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHelper';
import AppRoutes from 'infrastructure/Routes';
import callApi from 'infrastructure/api/CallApi';
import {
  shouldDisplayDesktopAnimatedBannerAd,
  shouldDisplayDesktopTowerAd,
} from 'infrastructure/freewheel/adBreakpoints';
import { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { setDailyWord } from 'slices/DailyWord/DailyWordSlice';
import { selectIsSignedIn } from 'slices/User/UserSlice';
import { ThemeProvider } from 'styled-components';
import { DailyWordDto } from 'types';

const { isClosed } = GetConfig();

const App: React.FC = () => {
  const isLoggedIn = useAppSelector(selectIsSignedIn);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!isClosed) {
      callApi<DailyWordDto>(FetchDailyWordUrl).then((response) => {
        dispatch(setDailyWord(response.data));
      });
    }
  }, [dispatch]);

  return (
    <ThemeProvider theme={defaultTheme}>
      <AdStyles />
      <GlobalStyles />
      <GlobalFonts />
      <Router>
        <main className={!isClosed ? 'main-container' : ''}>
          <HallmarkHeader isLoggedIn={isLoggedIn} />
          {isClosed ? null : <NavBar />}
          <Header isLoggedIn={isLoggedIn} />
          {isClosed ? <ClosedPage /> : <AppRoutes />}
          <FixedAd shouldDisplay={shouldDisplayDesktopTowerAd} />
          <AnimatedBanner shouldDisplayDesktopVersion={shouldDisplayDesktopAnimatedBannerAd} />
        </main>
        <HallmarkFooter />
      </Router>
    </ThemeProvider>
  );
};

export default App;
