import styled from 'styled-components';

export const CountdownWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
    margin-bottom: 20px;
    gap: 12px;
  }

  .countdown__column {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 12px;
  }

  .countdown__value-label {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 12px;
    letter-spacing: 0.03em;
    font-size: 16px;
    line-height: 100%;
    color: ${({ theme }) => theme.colors.black};
    font-weight: ${({ theme }) => theme.fonts.fontWeightBold};

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
      font-size: 12px;
    }
  }

  .countdown__value {
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 44px;
    letter-spacing: 0.03em;
    font-size: 44px;
    font-weight: ${({ theme }) => theme.fonts.fontWeightBold};
    font-family: ${({ theme }) => theme.fonts.albragrotesk};
    color: ${({ theme }) => theme.colors.crimsonLove};
    background: ${({ theme }) => theme.colors.white};
    box-shadow: 0px 28px 44px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    border: 1px solid #cdcdcd;
    width: 93px;
    height: 83px;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
      font-size: 33px;
      width: 70px;
      height: 63px;
    }
  }

  .countdown__colon {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 7px;
    position: relative;
    top: -15px;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
      gap: 5.32px;
      top: -12px;
    }

    span {
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: ${({ theme }) => theme.colors.oceanGreen};

      @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
        width: 4.56px;
        height: 4.56px;
      }
    }
  }
`;
