import GetConfig from '../../helpers/GetConfig';
import { Wrapper } from './HallmarkHeader.style';
import classNames from 'classnames';
import { SignedOutLandingRoute, SignUpFormRoute } from 'constants/routes';
import useLinkTarget from 'hooks/useLinkTarget';
import { HallmarkChannelLogo } from 'images/logos';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';

const { navBarColor, navBarLink, navBarFranchise } = GetConfig();

interface Props {
  isLoggedIn: boolean;
}

const { isClosed } = GetConfig();
const HallmarkHeader = ({ isLoggedIn }: Props) => {
  const [navOpen, setNavOpen] = useState(false);
  const [showNav, setShowNav] = useState(false);

  const linkTarget = useLinkTarget();
  const toggleNav = () => {
    setNavOpen(!navOpen);
  };

  const { pathname } = useLocation();

  return (
    <Wrapper>
      <header className={classNames('PageHeader', showNav && 'navbar-open', (isLoggedIn || isClosed) && 'logged-in')}>
        <div className="PageHeader-container" style={{ backgroundColor: navBarColor }}>
          <CSSTransition in={navOpen} timeout={0} classNames="PageHeader-burger" className="PageHeader-burger">
            <div onMouseDown={toggleNav}>
              <span className="PageHeader-burger-bar"></span>
              <span className="PageHeader-burger-bar"></span>
              <span className="PageHeader-burger-bar"></span>
            </div>
          </CSSTransition>
          <div className="PageHeader-logo">
            <a href="https://www.hallmarkchannel.com" aria-label="logo" target={linkTarget}>
              <HallmarkChannelLogo color="#ffffff" />
            </a>
          </div>

          <nav className="PageHeader-navigation-top">
            <ul className="PageHeader-navigation-items">
              <li className="PageHeader-navigation-items-item ">
                <a
                  href="https://www.hallmarkchannel.com/schedule"
                  aria-label="PageHeader-container"
                  target={linkTarget}
                >
                  Schedule
                </a>
              </li>
              <li className="PageHeader-navigation-items-item ">
                <a href="https://www.hallmarkchannel.com/movies" aria-label="PageHeader-container" target={linkTarget}>
                  Movies
                </a>
              </li>
              <li className="PageHeader-navigation-items-item ">
                <a href={navBarLink} aria-label="PageHeader-container" target={linkTarget}>
                  {navBarFranchise}
                </a>
              </li>
              <li className="PageHeader-navigation-items-item ">
                <a href="https://www.hallmarkchannel.com/shows" aria-label="PageHeader-container" target={linkTarget}>
                  Shows
                </a>
              </li>
              <li className="PageHeader-navigation-items-item ">
                <a
                  href="https://www.watchhallmarktv.com/hclive.html"
                  target={linkTarget}
                  rel="noopener noreferrer"
                  aria-label="PageHeader-container"
                >
                  Watch Live
                </a>
              </li>
            </ul>
          </nav>
          <div className="PageHeader-search">
            <div className="PageHeader-channel">
              <a
                href="https://www.hallmarkchannel.com/channel-locator"
                aria-label="PageHeader-channel"
                target={linkTarget}
              >
                Channel Locator
              </a>
            </div>
          </div>
        </div>
        <div
          className={classNames(
            'background-bar',
            (pathname === SignedOutLandingRoute || pathname === SignUpFormRoute || isClosed) && 'background-bar--lg'
          )}
        ></div>
        <CSSTransition
          in={navOpen}
          timeout={200}
          classNames="PageHeader-navigation"
          className="PageHeader-navigation"
          style={{ backgroundColor: navBarColor }}
          onExiting={() => setShowNav(true)}
          onEnter={() => setShowNav(true)}
          onExited={() => setShowNav(false)}
        >
          <div>
            <ul className="PageHeader-navigation-items">
              <li className="PageHeader-navigation-items-item">
                <a
                  href="https://www.hallmarkchannel.com/schedule"
                  target={linkTarget}
                  rel="noopener noreferrer"
                  aria-label="axe-fix"
                >
                  Schedule
                </a>
              </li>
              <li className="PageHeader-navigation-items-item">
                <a
                  href="https://www.hallmarkchannel.com/movies"
                  target={linkTarget}
                  rel="noopener noreferrer"
                  aria-label="axe-fix"
                >
                  Movies
                </a>
              </li>
              <li className="PageHeader-navigation-items-item">
                <a href={navBarLink} target={linkTarget} rel="noopener noreferrer" aria-label="PageHeader-container">
                  {navBarFranchise}
                </a>
              </li>
              <li className="PageHeader-navigation-items-item ">
                <a href="https://www.hallmarkchannel.com/shows" aria-label="PageHeader-container" target={linkTarget}>
                  Shows
                </a>
              </li>
              <li className="PageHeader-navigation-items-item">
                <a
                  href="https://www.watchhallmarktv.com/hclive.html"
                  target={linkTarget}
                  rel="noopener noreferrer"
                  aria-label="axe-fix"
                >
                  Watch Live
                </a>
              </li>
              <li className="PageHeader-navigation-items-item">
                <a
                  href="https://www.hallmarkchannel.com/channel-locator"
                  target={linkTarget}
                  rel="noopener noreferrer"
                  aria-label="axe-fix"
                >
                  Channel Locator
                </a>
              </li>
            </ul>
          </div>
        </CSSTransition>
      </header>
    </Wrapper>
  );
};

export default HallmarkHeader;
