import EventTrackingData from 'constants/eventData';
import playIcon from 'images/play-video-button.png';
import { trackEventData } from 'infrastructure/tracking/GoogleAnalytics';
import { FC, useRef, useState } from 'react';

interface Props {
  videoUrl: string;
  posterUrl: string;
}

const HintVideoPlayer: FC<Props> = ({ videoUrl, posterUrl }) => {
  const ref = useRef<HTMLVideoElement>(null);
  const [showControls, setShowControls] = useState(false);
  const [showPlayButton, setShowPlayButton] = useState(true);

  const handleOnPlayClick = () => {
    if (!ref.current) {
      return;
    }

    ref.current.play();
    setShowControls(true);
    setShowPlayButton(false);
  };

  const handleOnVideoPause = () => {
    setShowControls(false);
    setShowPlayButton(true);
  };

  return (
    <>
      <video
        ref={ref}
        src={videoUrl}
        poster={posterUrl}
        controls={showControls}
        className="img-fluid"
        onPlay={() => trackEventData(EventTrackingData.HintVideoStart)}
        onEnded={() => trackEventData(EventTrackingData.HintVideoEnd)}
        onPause={handleOnVideoPause}
      />
      {showPlayButton ? (
        <button className="play-video-button" onClick={handleOnPlayClick}>
          <img src={playIcon} alt="Play" />
        </button>
      ) : null}
    </>
  );
};

export default HintVideoPlayer;
