import desktopBackground from '../images/bg_desktop.jpg';
import mobileBackground from '../images/bg_mobile.webp';
import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    min-height: 100%;
    width: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-style: normal;
    overflow-x: hidden;
    font-family: ${({ theme }) => theme.fonts.albragrotesk};
    background-image: url(${mobileBackground});
    background-repeat: no-repeat;
    background-size: cover;

    @media screen and (min-width: 576px) {
        background-image: url(${desktopBackground});
    }
  }

  .main-container {
    min-height: calc(100vh - 200px);

    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.xxl}) {
        min-height: 100%;
    }
  }

  .container {
    width: auto;
    margin: auto;
    padding-left: 24px;
    padding-right: 24px;
  }

  .navbar {
    margin-bottom: 0;
  }

  .loader-4 {
    align-items: center;
    display: flex;
    justify-content: center;
    height: 30px;
    border-radius: 100%;
    margin: 0 auto;
    width: 60px;
  }
  
  .loader-4 span {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background-color: #fff;
    margin: 0 5px;
    opacity: 0;
  }
  
  .loader-4 span:nth-child(1) {
    animation: opacitychange 1s ease-in-out infinite;
  }
  
  .loader-4 span:nth-child(2) {
    animation: opacitychange 1s ease-in-out 0.33s infinite;
  }
  
  .loader-4 span:nth-child(3) {
    animation: opacitychange 1s ease-in-out 0.66s infinite;
  }
  
  @keyframes opacitychange {
    0%,
    100% {
      opacity: 0;
    }
  
    60% {
      opacity: 1;
    }
  }
`;

export default GlobalStyles;
