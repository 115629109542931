import { unicodeSplit } from '../../lib/words';
import { Cell } from './Cell';

type Props = {
  guess: string;
  className: string;
  dailyWord: string;
};

export const CurrentRow = ({ guess, className, dailyWord }: Props) => {
  const splitGuess = unicodeSplit(guess);
  const emptyCells = Array.from(Array(dailyWord.length - splitGuess.length));
  const classes = `flex justify-center mb-2 ${className} row-length-${dailyWord.length}`;

  return (
    <div className={classes}>
      {splitGuess.map((letter, i) => (
        <Cell key={i} value={letter} wordLength={dailyWord.length} />
      ))}
      {emptyCells.map((_, i) => (
        <Cell key={i} wordLength={dailyWord.length} />
      ))}
    </div>
  );
};
