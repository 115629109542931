import { Wrapper } from './Loader.style';

const Loader = () => {
  return (
    <Wrapper>
      <div className="loading-container">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="hallmark-loader"
          fill="none"
          width="100%"
          height="100%"
          viewBox="0 0 22 17"
          preserveAspectRatio="xMidYMid meet"
        >
          <linearGradient id="lg" x1="0.5" y1="1" x2="0.5" y2="0">
            <stop offset="0%" stopOpacity="1" stopColor="#A80808" />
            <stop offset="100%" stopOpacity="1" stopColor="#A80808">
              <animate attributeName="offset" values="0;1" dur="2s" begin="0s" repeatCount="indefinite" />
            </stop>
            <stop offset="0%" stopOpacity="1" stopColor="#FFFFFF">
              <stop offset="100%" stopOpacity="1" stopColor="#FFFFFF" />
            </stop>
          </linearGradient>
          <path
            fill="url(#lg)"
            d="M11.676.797a.808.808 0 00-.79-.797.808.808 0 00-.791.797c0 .43.365.797.79.797.426 0 .791-.368.791-.797zm5.473 3.557a.808.808 0 00-.79-.797.808.808 0 00-.791.797c0 .43.365.797.79.797.426 0 .791-.368.791-.797zm-10.885 0a.808.808 0 00-.79-.797.808.808 0 00-.791.797c0 .43.364.797.79.797.426 0 .79-.368.79-.797zm15.081.307a.808.808 0 00-.79-.798.808.808 0 00-.791.798c0 .429.365.797.79.797.426 0 .791-.368.791-.797zm-19.338 0a.808.808 0 00-.79-.798.808.808 0 00-.791.798c0 .429.365.797.79.797.426 0 .79-.368.79-.797zM19.764 6.5c.182-.43-.183-.553-.365-.307-.547.981-1.338 2.453-2.19 3.311-.425.43-1.215.246-1.276-.429-.183-1.165 0-2.024.06-2.882 0-.368-.303-.43-.425-.123-.365.797-.608 1.779-1.277 2.576-.608.674-1.46.43-1.885-.123-.913-1.226-1.156-4.415-1.277-5.887-.061-.307-.426-.307-.426 0-.122 1.472-.365 4.66-1.277 5.887-.426.613-1.277.797-1.885.123-.73-.797-.973-1.779-1.277-2.576-.122-.306-.426-.245-.426.123.06.858.243 1.778.06 2.882-.12.675-.85.92-1.337.43-.912-.92-1.642-2.392-2.19-3.312-.182-.307-.547-.123-.364.306C3.223 9.2 4.622 13.185 4.743 13.37c.122.122.183.184.244.122 1.338-.736 3.71-1.042 5.898-1.042 2.19 0 4.561.306 5.9 1.042.06.062.12 0 .242-.122.122-.123 1.52-4.17 2.737-6.869zm-8.879 6.99c-2.31 0-5.716.43-5.716 1.534 0 1.165 3.406 1.594 5.716 1.594 2.311 0 5.717-.43 5.717-1.594 0-1.104-3.406-1.534-5.717-1.534zm0 2.576c-2.189 0-4.986-.368-4.986-1.042 0-.614 2.797-.982 4.986-.982 2.129 0 4.987.368 4.987.982 0 .674-2.858 1.042-4.987 1.042z"
          ></path>
        </svg>
      </div>
    </Wrapper>
  );
};

export default Loader;
