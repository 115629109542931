import { Cell } from './Cell';

type Props = {
  wordLength: number;
};

export const EmptyRow: React.FC<Props> = ({ wordLength }) => (
  <div className={`mb-2 flex justify-center row-length-${wordLength}`}>
    {Array.from(Array(wordLength)).map((_, i) => (
      <Cell key={i} wordLength={wordLength} />
    ))}
  </div>
);
