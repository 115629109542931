import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../infrastructure/redux/store';

export interface WebViewState {
    isWebView: boolean;
}

const initialState: WebViewState = {
    isWebView: false,
};

export const webViewSlice = createSlice({
    name: 'webview',
    initialState,
    reducers: {
        setIsWebView: (state, action: PayloadAction<boolean>) => {
            state.isWebView = action.payload;
        },
    },
});

export const { setIsWebView } = webViewSlice.actions;

export const selectIsWebView = (state: RootState) => state.webView.isWebView;
export const selectLinkTarget: (state: RootState) => '' | '_blank' = (state) =>
    state.webView.isWebView ? '' : '_blank';

export default webViewSlice.reducer;
