import React from 'react';

function IconTwitter() {
  return (
    <svg id="mono-icon-twitter" viewBox="0 0 19 16" width="19" height="16" preserveAspectRatio="xMidYMid meet">
      <path
        fillRule="evenodd"
        d="M16.48 4.594c.024.094.036.246.036.457 0 1.687-.41 3.316-1.23 4.887-.845 1.664-2.028 2.988-3.552 3.972-1.664 1.102-3.574 1.652-5.73 1.652-2.063 0-3.95-.55-5.66-1.652.258.024.55.035.879.035 1.71 0 3.246-.527 4.605-1.582-.82 0-1.54-.24-2.162-.72a3.671 3.671 0 01-1.283-1.811c.234.023.457.035.668.035.328 0 .656-.035.984-.105a3.72 3.72 0 01-2.11-1.301 3.54 3.54 0 01-.843-2.32v-.07a3.475 3.475 0 001.652.492A4.095 4.095 0 011.54 5.227a3.546 3.546 0 01-.457-1.776c0-.644.176-1.26.527-1.845a10.18 10.18 0 003.358 2.742 10.219 10.219 0 004.236 1.125 5.124 5.124 0 01-.07-.844c0-.656.164-1.272.492-1.846a3.613 3.613 0 011.336-1.353A3.565 3.565 0 0112.79.938c.54 0 1.037.105 1.494.316.457.21.861.492 1.213.844a7.51 7.51 0 002.356-.88c-.282.868-.82 1.548-1.618 2.04a7.93 7.93 0 002.11-.598 8.114 8.114 0 01-1.864 1.934z"
      ></path>
    </svg>
  );
}

export default IconTwitter;
