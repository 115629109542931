import styled from 'styled-components';

export const Wrapper = styled.div`
  max-width: 764px;
  border-radius: 16px;
  box-shadow: 0px 28px 44px rgba(0, 0, 0, 0.05);
  background-color: ${({ theme }) => theme.colors.white};

  margin-top: 24px;
  border-radius: 10px;
  padding: 30px 25px 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
    display: none;
  }

  h6 {
    font-size: 21px;
    color: ${({ theme }) => theme.colors.underTheMistletoe};
    font-weight: ${({ theme }) => theme.fonts.fontWeightBold};
    text-transform: uppercase;
    letter-spacing: 0.11em;
    line-height: 100%;
    margin-right: 20px;
  }
`;

export const ShareSocialButtonWrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: red;
  box-shadow: 0px 0px 4.88529px rgba(0, 0, 0, 0.1), 0px 0.977058px 13.6788px rgba(66, 103, 178, 0.4);
  height: 58px;
  width: 58px;
`;

export const ShareButtonWrapper = styled.button`
  height: 50px;
  width: 264px;
  border: 2px solid ${({ theme }) => theme.colors.underTheMistletoe};
  border-radius: 32px;
  color: ${({ theme }) => theme.colors.underTheMistletoe};
  gap: 7.5px;
  letter-spacing: 0.04em;
  font-size: 16px;
  font-weight: ${({ theme }) => theme.fonts.fontWeightBold};
  line-height: 100%;
  text-transform: uppercase;
`;
