import { trackEventData } from '../../infrastructure/tracking/GoogleAnalytics';
import { BaseModal } from './BaseModal';
import { RecipeModalBackground } from './BaseModal.style';
import eventTrackingData from 'constants/eventData';
import RightArrowIcon from 'images/icons/RightArrowIcon';

type Props = {
  isOpen: boolean;
  handleClose: () => void;
  recipeUrl: string;
  recipeImageUrl: string;
};

export const RecipeModal = ({ isOpen, handleClose, recipeUrl, recipeImageUrl }: Props) => {
  return (
    <BaseModal
      title="Awesome!\nReturn tomorrow for a new word."
      modalName="recipe"
      isOpen={isOpen}
      handleClose={handleClose}
    >
      <RecipeModalBackground recipeDesktop={recipeImageUrl} recipeMobile={recipeImageUrl}>
        <a
          className="get-recipe-btn"
          href={`${recipeUrl}?utm_source=HallmarkChannel&utm_medium=RecipeModal&utm_campaign=JoyToTheWord`}
          onClick={() => trackEventData(eventTrackingData.RecipeClick)}
          target="_blank"
          rel="noreferrer"
        >
          <span>Get Recipe</span>
          <RightArrowIcon />
        </a>
      </RecipeModalBackground>
    </BaseModal>
  );
};
