import useFreewheel from '../../infrastructure/freewheel/useFreewheel';
import AdSlot from './AdSlot';
import GetConfig from 'helpers/GetConfig';
import { useEffect } from 'react';
import { useLocation } from 'react-router';

const { shouldShowTestAds } = GetConfig();

const FooterAd = ({ shouldDisplay, classNames }) => {
  const location = useLocation();
  const freewheelRequest = useFreewheel();

  useEffect(() => {
    if (shouldDisplay) {
      freewheelRequest(location.pathname);
    }
  }, [location, freewheelRequest, shouldDisplay]);
  if (!shouldDisplay) {
    return null;
  }
  return <AdSlot width={300} height={250} slotId="fixed_ad" classNames={classNames} isTest={shouldShowTestAds} />;
};

export default FooterAd;
