import styled from 'styled-components';

export const StatsButton = styled.a`
  background-color: #caedde33;
  letter-spacing: 0.03em;
  color: ${({ theme }) => theme.colors.underTheMistletoe};

  &:hover,
  &:focus,
  &:active {
    cursor: pointer;
  }
`;

export const LogoutButton = styled.button`
  color: ${({ theme }) => theme.colors.crimsonLove};

  img {
    padding-right: 16px;
  }
`;

export const Navbar = styled.div`
  .not-you-btn::after {
    content: '';
    display: block;
    width: 0;
    height: 1px;
    background: ${({ theme }) => theme.colors.crimsonLove};
    transition: width 0.3s;
  }

  .not-you-btn:hover::after {
    width: 100%;
  }
`;
