import styled from 'styled-components';

export const Wrapper = styled.div`
  .stats__container {
    max-width: 764px;
    border-radius: 16px;
    box-shadow: 0px 28px 44px rgba(0, 0, 0, 0.05);
    background-color: ${({ theme }) => theme.colors.white};
  }

  .stats__share {
    margin-top: 24px;
    border-radius: 10px;
    padding: 30px 25px 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
      display: none;
    }

    h6 {
      font-size: 21px;
      color: ${({ theme }) => theme.colors.underTheMistletoe};
      font-weight: ${({ theme }) => theme.fonts.fontWeightBold};
      text-transform: uppercase;
      letter-spacing: 0.11em;
      line-height: 100%;
      margin-right: 20px;
    }
  }

  .stats__content {
    padding: 48px 49px 48px;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
      padding: 16px 34px 32px 23px;
    }
  }

  h5 {
    color: ${({ theme }) => theme.colors.underTheMistletoe};
    font-weight: ${({ theme }) => theme.fonts.fontWeightBold};
    font-size: 29px;
    line-height: 29px;
    text-transform: uppercase;
    margin-bottom: 16px;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
      font-size: 16px;
      letter-spacing: 0.05em;
      line-height: 16px;
      margin-bottom: 17px;
    }
  }

  .stats__title {
    font-size: 29px;
    line-height: 29px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    text-align: center;
    font-weight: ${({ theme }) => theme.fonts.fontWeightBold};
    margin-bottom: 27px;
    color: ${({ theme }) => theme.colors.crimsonLove};

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
      font-size: 16px;
      line-height: 16px;
      margin-bottom: 16px;
      color: ${({ theme }) => theme.colors.underTheMistletoe};
    }
  }

  .stat-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
    padding: 32px 5px;
    height: 169px;
    width: 134px;
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.crimsonLove};
    box-shadow: 0px 12px 18px -8px rgba(211, 47, 60, 0.3);
    border-radius: 12px;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
      gap: 4px;
      width: 66px;
      height: 84px;
      padding: 12px 5px;
      border-radius: 12px;
    }

    b {
      line-height: 67px;
      font-size: 67px;

      @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
        font-size: 33px;
        line-height: 33px;
      }
    }

    .stat-item--lg {
      font-size: 67px;
      line-height: 100%;

      @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
        font-size: 33px;
        line-height: 100%;
      }
    }

    .stat-item--sm {
      font-size: 55px;
      line-height: 100%;
      margin-bottom: 10px;

      @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
        font-size: 28px;
        line-height: 100%;
        margin-bottom: 4.75px;
      }
    }

    span {
      font-size: 21px;
      line-height: 21px;
      letter-spacing: 0.06em;
      text-align: center;
      max-width: 78px;

      @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
        font-size: 11px;
        line-height: 11px;
      }
    }
  }

  .statbar {
    gap: 43px;
    margin-bottom: 32px;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
      gap: 12px;
    }
  }

  h4 {
    font-size: 29px;
    line-height: 100%;
    letter-spacing: 0.05em;
    text-align: center;
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.underTheMistletoe};
    font-weight: ${({ theme }) => theme.fonts.fontWeightBold};

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
      font-size: 16px;
    }
  }

  .histogram-row__index {
    font-size: 21px;
    line-height: 29px;
    font-weight: ${({ theme }) => theme.fonts.fontWeightSemi};

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
      font-size: 19.0569px;
      line-height: 26px;
    }
  }

  .stats__histogram {
    margin: 0.5rem 0;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
      max-width: 300px;
      margin: 0.5rem auto;
    }
  }

  .stats__histogram-row {
    margin: 7px 0;
  }

  .histogram-row__line {
    height: 41px;
    font-weight: ${({ theme }) => theme.fonts.fontWeightSemi};
    margin-left: 12px;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
      height: 27px;
    }

    div {
      height: 100%;
      border-radius: 8px;
      font-size: 21px;
      line-height: 100%;
      padding-right: 20px;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
        font-size: 19px;
        padding-right: 8px;
      }
    }
  }

  .progress--bold {
    background-color: ${({ theme }) => theme.colors.underTheMistletoe};
    box-shadow: 0px 12px 18px -8px rgba(0, 98, 76, 0.12);
    color: ${({ theme }) => theme.colors.white};
  }

  .progress--light {
    background-color: rgba(64, 160, 120, 0.2);
    color: ${({ theme }) => theme.colors.underTheMistletoe};
  }

  .share-button {
    height: 50px;
    width: 264px;
    border: 2px solid ${({ theme }) => theme.colors.underTheMistletoe};
    border-radius: 32px;
    color: ${({ theme }) => theme.colors.underTheMistletoe};
    gap: 7.5px;
    letter-spacing: 0.04em;
    font-size: 16px;
    font-weight: ${({ theme }) => theme.fonts.fontWeightBold};
    line-height: 100%;
    text-transform: uppercase;
  }

  .share-button-container {
    margin: 0 auto;
  }
`;
