import { BaseModal } from 'components/modals/BaseModal';
import HintVideoPlayer from 'components/video/VideoPlayer';

interface Props {
  isOpen: boolean;
  handleOnCloseClick: () => void;
  imageUrl: string;
  videoUrl: string;
  description: string | null;
}

const HintModal = ({ isOpen, handleOnCloseClick, imageUrl, videoUrl, description }: Props) => {
  const getContent = () => {
    //if (description) {
    //  return <span>{description}</span>;
    //}

    if (videoUrl) {
      return <HintVideoPlayer videoUrl={videoUrl} posterUrl={imageUrl} />;
    }

    return <img src={imageUrl} alt="hint" />;
  };

  return (
    <BaseModal isOpen={isOpen} modalName="hint" handleClose={handleOnCloseClick} shouldHaveMinimalPadding={true}>
      <div className="hint-modal__content">{getContent()}</div>
    </BaseModal>
  );
};

export default HintModal;
