/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef } from 'react';
import { trackPageView } from 'infrastructure/tracking/GoogleAnalytics';
import { PageData } from 'types/Tracking';

const usePageView: (pageData: PageData) => void = ({ title, path }) => {

    const hasTrackedPageViewRef = useRef<boolean>(false);
    useEffect(() => {
        if (!hasTrackedPageViewRef.current) {
            hasTrackedPageViewRef.current = true;
            trackPageView(title, path);
        }
    }, []);
};

export default usePageView;
