import { trackEventData } from '../../infrastructure/tracking/GoogleAnalytics';
import { ShareSocialButtonWrapper } from 'components/stats/ShareResults.style';
import eventTrackingData from 'constants/eventData';
import GetConfig from 'helpers/GetConfig';
import { getTextToShare } from 'helpers/ShareHelper';
// import { useAppSelector } from 'hooks/useReduxHelper';
import twitterIcon from 'images/icons/twitter.svg';
import { ShareResultsProps } from 'types/Sharing';

const TwitterButton: React.FC<ShareResultsProps> = ({ solution, guesses, isGameLost }) => {
  //   const isWebView = useAppSelector(selectIsWebView);
  const isWebView = false;
  function shareOnTwitter() {
    trackEventData(eventTrackingData.TwitterShareClick);
    const config = GetConfig();
    const tweet = getTextToShare(isGameLost, guesses, solution, true);
    const twitterUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(tweet)}&url=${config.appUrl}`;
    if (isWebView) {
      window.location.href = twitterUrl;
    } else {
      window.open(twitterUrl, 'Tweet', 'location=0,status=0,width=800,height=650');
    }
  }

  return (
    <ShareSocialButtonWrapper
      style={{
        backgroundColor: '#1B95E0',
      }}
      onClick={shareOnTwitter}
    >
      <img src={twitterIcon} alt={'Twitter'} />
    </ShareSocialButtonWrapper>
  );
};

export default TwitterButton;
