export const GAME_TITLE = process.env.REACT_APP_GAME_NAME!;

export const WIN_MESSAGES = ['Great Job!', 'Awesome', 'Well done!'];
export const GAME_COPIED_MESSAGE = 'Game copied to clipboard';
export const NOT_ENOUGH_LETTERS_MESSAGE = 'Not enough letters';
export const WORD_NOT_FOUND_MESSAGE = 'Word not found';
export const CORRECT_WORD_MESSAGE = (solution: string) => `The word was ${solution}`;
export const WRONG_SPOT_MESSAGE = (guess: string, position: number) => `Must use ${guess} in position ${position}`;
export const NOT_CONTAINED_MESSAGE = (letter: string) => `Guess must contain ${letter}`;
export const ENTER_TEXT = 'Enter';
export const DELETE_TEXT = 'Delete';
export const STATISTICS_TITLE = 'Your Stats';
export const STATISTICS_SHARE_TITLE = 'Share your result:';
export const VIEW_STATS_TEXT = 'Awesome! Check out your STATS and return tomorrow for a new word.';
export const GUESS_DISTRIBUTION_TEXT = 'Guess Distribution';
export const NEW_WORD_TEXT = 'Next Joy To The Word';
export const SHARE_TEXT = 'Share your result';
export const SHARE_FAILURE_TEXT =
  'Unable to share the results. This feature is available only in secure contexts (HTTPS), in some or all supporting browsers.';
export const MIGRATE_BUTTON_TEXT = 'Transfer';
export const MIGRATE_DESCRIPTION_TEXT = 'Click here to transfer your statistics to a new device.';
export const TOTAL_TRIES_TEXT = 'Played';
export const SUCCESS_RATE_TEXT = 'Win %';
export const CURRENT_STREAK_TEXT = 'Current Streak';
export const BEST_STREAK_TEXT = 'Max Streak';
export const DISCOURAGE_INAPP_BROWSER_TEXT =
  "You are using an embedded browser and may experience problems sharing or saving your results. We encourage you rather to use your device's default browser.";

export const DATEPICKER_TITLE = 'Choose a past date';
export const DATEPICKER_CHOOSE_TEXT = 'Choose';
export const DATEPICKER_TODAY_TEXT = 'today';
export const ARCHIVE_GAMEDATE_TEXT = 'Game date';
