import { loadState, saveState } from './savedState';
import { combineReducers, configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import dailyWordReducer from 'slices/DailyWord/DailyWordSlice';
import userReducer from 'slices/User/UserSlice';
import webViewReducer from 'slices/webview/WebViewSlice';

const preloadedState = loadState();
const rootReducer = combineReducers({
  webView: webViewReducer,
  user: userReducer,
  dailyWord: dailyWordReducer,
});

export const store = configureStore({
  reducer: rootReducer,
  preloadedState: preloadedState,
});

store.subscribe(() =>
  saveState({
    user: { ...store.getState().user },
  })
);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof rootReducer>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
