import { MAX_CHALLENGES } from '../../constants/settings';
import { CompletedRow } from './CompletedRow';
import { CurrentRow } from './CurrentRow';
import { EmptyRow } from './EmptyRow';

type Props = {
  dailyWord: string;
  guesses: string[];
  currentGuess: string;
  isRevealing?: boolean;
  currentRowClassName: string;
};

export const Grid = ({ dailyWord, guesses, currentGuess, isRevealing, currentRowClassName }: Props) => {
  const empties = guesses.length < MAX_CHALLENGES - 1 ? Array.from(Array(MAX_CHALLENGES - 1 - guesses.length)) : [];

  return (
    <>
      {guesses.map((guess, i) => (
        <CompletedRow
          key={i}
          dailyWord={dailyWord}
          guess={guess}
          isRevealing={isRevealing && guesses.length - 1 === i}
        />
      ))}
      {guesses.length < MAX_CHALLENGES && (
        <CurrentRow guess={currentGuess} className={currentRowClassName} dailyWord={dailyWord} />
      )}
      {empties.map((_, i) => (
        <EmptyRow wordLength={dailyWord.length} key={i} />
      ))}
    </>
  );
};
