const gameStateKey = 'JoyToTheWordGameState'

export type StoredGameState = {
  guesses: string[]
  solution: string
}

export const saveGameStateToLocalStorage = (
  gameState: StoredGameState
) => {
  try {
    localStorage.setItem(gameStateKey, JSON.stringify(gameState))
  } catch { }
}

export const loadGameStateFromLocalStorage = () => {
  try {
    const state = localStorage.getItem(gameStateKey)
    return state ? (JSON.parse(state) as StoredGameState) : null
  } catch {
    return null;
  }
}

export const removeGameStateFromLocalStorage = () => {
  try {
    localStorage.removeItem(gameStateKey)
  } catch { }
}