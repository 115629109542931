import AlbraGroteskBlackOtf from './Albra-Grotesk-Black.otf';
import AlbraGroteskBlackWoff from './Albra-Grotesk-Black.woff';
import AlbraGroteskBoldOtf from './Albra-Grotesk-Bold.otf';
import AlbraGroteskBoldWoff from './Albra-Grotesk-Bold.woff';
import AlbraGroteskLightOtf from './Albra-Grotesk-Light.otf';
import AlbraGroteskLightWoff from './Albra-Grotesk-Light.woff';
import AlbraGroteskMediumOtf from './Albra-Grotesk-Medium.otf';
import AlbraGroteskMediumWoff from './Albra-Grotesk-Medium.woff';
import AlbraGrotesRegularOtf from './Albra-Grotesk-Regular.otf';
import AlbraGroteskRegularWoff from './Albra-Grotesk-Regular.woff';
import AlbraGroteskSemiOtf from './Albra-Grotesk-Semi.otf';
import AlbraGroteskSemiWoff from './Albra-Grotesk-Semi.woff';
import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
    @font-face {
        font-family: 'Albra Grotesk';
        src: local('Albra Grotesk'), local('AlbraGrotesk'),
        url(${AlbraGroteskBlackOtf}) format('otf'),
        url(${AlbraGroteskBlackWoff}) format('woff');
        font-weight: 800;
        font-style: normal;
    }
    @font-face {
        font-family: 'Albra Grotesk';
        src: local('Albra Grotesk'), local('AlbraGrotesk'),
        url(${AlbraGroteskBoldOtf}) format('otf'),
        url(${AlbraGroteskBoldWoff}) format('woff');
        font-weight: 700;
        font-style: normal;
    }
    @font-face {
        font-family: 'Albra Grotesk';
        src: local('Albra Grotesk'), local('AlbraGrotesk'),
        url(${AlbraGroteskSemiOtf}) format('otf'),
        url(${AlbraGroteskSemiWoff}) format('woff');
        font-weight: 600;
        font-style: normal;
    }
    @font-face {
        font-family: 'Albra Grotesk';
        src: local('Albra Grotesk'), local('AlbraGrotesk'),
        url(${AlbraGroteskMediumOtf}) format('otf'),
        url(${AlbraGroteskMediumWoff}) format('woff');
        font-weight: 500;
        font-style: normal;
    }
    @font-face {
        font-family: 'Albra Grotesk';
        src: local('Albra Grotesk'), local('AlbraGrotesk'),
        url(${AlbraGrotesRegularOtf}) format('otf'),
        url(${AlbraGroteskRegularWoff}) format('woff');
        font-weight: 400;
        font-style: normal;
    }
    @font-face {
        font-family: 'Albra Grotesk';
        src: local('Albra Grotesk'), local('AlbraGrotesk'),
        url(${AlbraGroteskLightOtf}) format('otf'),
        url(${AlbraGroteskLightWoff}) format('woff');
        font-weight: 100;
        font-style: normal;
    }
`;
