import { Cell } from '../grid/Cell';
import { BaseModal } from './BaseModal';
import { Wrapper } from './InfoModal.style';

type Props = {
  isOpen: boolean;
  handleClose: () => void;
};

export const InfoModal = ({ isOpen, handleClose }: Props) => {
  return (
    <BaseModal title="Visit every day to guess a new word!" modalName="info" isOpen={isOpen} handleClose={handleClose}>
      <Wrapper>
        <ul>
          <li>
            <span>
              A correct letter turns dark green, a correct letter in the wrong place turns teal, and an incorrect letter
              turns red.
            </span>
          </li>
        </ul>

        <div className="mb-1 mt-4 flex justify-center">
          <Cell isRevealing={true} isCompleted={true} value="B" status="correct" />
          <Cell value="E" isCompleted={true} />
          <Cell value="L" isCompleted={true} />
          <Cell value="L" isCompleted={true} />
          <Cell value="S" isCompleted={true} />
        </div>
        <p className="text-sm text-black-500 modal__row-description">
          The letter B is in the word and in the correct place.
        </p>

        <div className="mb-1 mt-4 flex justify-center">
          <Cell value="C" isCompleted={true} />
          <Cell value="A" isCompleted={true} />
          <Cell isRevealing={true} isCompleted={true} value="N" status="present" />
          <Cell value="D" isCompleted={true} />
          <Cell value="Y" isCompleted={true} />
        </div>
        <p className="text-sm text-black-500 modal__row-description">
          The letter N is in the word and in the wrong place.
        </p>

        <div className="mb-1 mt-4 flex justify-center">
          <Cell value="S" isCompleted={true} />
          <Cell value="C" isCompleted={true} />
          <Cell value="A" isCompleted={true} />
          <Cell isRevealing={true} isCompleted={true} value="R" status="absent" />
          <Cell value="F" isCompleted={true} />
        </div>
        <p className="text-sm text-black-500 modal__row-description mb-0">
          The letter R is not in the word in any place.
        </p>
      </Wrapper>
    </BaseModal>
  );
};
