import styled from 'styled-components';

export const Wrapper = styled.div`
  @media screen and (min-width: 768px) {
    background-color: #fef5f6;
  }
`;

export const MovieTitle = styled.span`
  color: ${({ theme }) => theme.colors.crimsonLove};
`;

export const OpenModalButton = styled.button`
  background-color: ${({ theme }) => theme.colors.crimsonLove};
  border-radius: 40px;
  font-weight: 700;
  font-size: 15px;
  line-height: 100%;
  letter-spacing: 0.05em;
  text-transform: uppercase;

  @media screen and (min-width: 768px) {
    font-size: 21px;
  }
`;
